.data {
    &.item {
        &.title {
            margin: 0;
            padding: 1rem;
            font-size: 17px;
            a {
                color: $font-color__base;
                font-weight: 400;
            }
            &.active {
                border-bottom: 3px solid #0072a0;
            }
        }
        &.navigation {
            width: 100%;
            .inner {
                display: flex;
                justify-content: center;
                background: #fff;
                width: 100%;
                &.fixed {
                    position: fixed;
                    z-index: 10000;
                    top: 0;
                    left: 0;
                    right: 0;
                }
            }
        }
        &.content {
			width: 80%;
			min-height: 200px;
			margin-top: 0.5rem;
            .counter {
                &:before {
                    content: '(';
                }
                &:after {
                    content: ')';
                }
            }
            h2 {
				font-size: 22px;
				color: $font-color__base;
				font-weight: 500;
				text-align: left;
				width: 20%;
            }
            table#product-attribute-specs-table {
                width: 100%;
            }
            &.wrapper {
                &.fixed {
                    padding-top: 78px; // navigation height
                }
            }
        }
    }
}

@include max-screen($screen__m){
    .product.info.detailed{
        .navigation{
            display: none !important;
        }
    }
   .data{
        &.item{
            &.title{
                padding: 0;
            }
            #product.info.accessories{
                margin-top: 0;
            }
            &.content{
                width: 100% !important;
                min-height: unset;
                margin-bottom: 2rem;
                &.wrapper{
                    width: 100%;
                    &.fixed{
                        padding-top: 0;
                    }
                }
            }
        }
    }
}
