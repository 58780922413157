.page-bottom {
    .content {
        .block.newsletter {
            width: auto;
            position: relative;
            margin: -110px 0 0 0;
            .title {
                display: none;
            }
            .content {
                width: 100%;
                max-width: 700px;
                border-radius: 3px;
                margin: 0 auto;
                background-color: #fff;
                position: relative;
                z-index: 1;
                box-shadow: 0px 1px 2px rgba(0,0,0,0.2);
            }

            .form.subscribe {
                display:flex;
                display: -ms-flexbox;
                align-items: flex-end;
                padding: 16px 30px 30px 30px;
                .field.newsletter {
                    display: flex;
                    display: -ms-flexbox;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    .material-icons {
                        border: 1px solid #fff;
                        border-radius: 50%;
                        padding: 15px;
                        box-shadow: 0px 1px 5px rgba(0,0,0,0.15);
                        font-size: 45px;
                        margin-top: -4rem;
                        margin-bottom: 2rem;
                        background-color: #fff;
                    }
                    h2 {
                        margin: 0 0 0.5rem 0;
                        font-weight: 600;
                    }
                    p {
                        margin-bottom: 1rem;
                        text-align: left;
                        font-weight: 300;
                        font-size: 15px;
                        width: 100%;
                    }
                    .control {
                        display: flex;
                        .action.subscribe.primary {
                            margin-left: 10px;
                            border-radius: 3px;
                            padding: calc(1rem - 4px) 1rem;
                        }
                    }
                }
            }
        }
    }
}


.page-bottom {
    background-color: #474747;
    .page-wrapper & {
        width: 100%;
        margin: 0;
        max-width: none;

    }
}

.page-footer {
    background-color: #474747;
    .footer.content {
        background-color: #474747;
        flex-grow: 1;

        .full-width {
            color: #fff;
            .widget.block.block-static-block {
                justify-content: center;
                .footer-links-container {
                    display: flex;
                    width: 100%;

                    ul {
                        &:last-child {
                            padding-right: 0;
                        }
                    }

                    li {
                        font-size: unset;
                        a {
                            font-size: 1.2em;
                        }
                    }
                    .social-media,
                    .extra-links {
                        grid-column: 3 / span 2;
                        -ms-grid-column: 3;
                        -ms-grid-column-span: 2;
                        text-align: center;
                    }
                    .social-media {
                    	display: grid;
                        display: -ms-grid;
						grid-template-columns: repeat(auto-fit, minmax(1rem, 1fr));
						align-items: center;
                        a {
                            padding: 0 1rem;
                        }
                        a:first-child {
                            text-align: right;
                        }
                        a:last-child {
                            text-align: left;
                        }
                    }
                    a {
                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.copyright {
    background: #fff;
    color: #000;
}

/* MOBILE */
@include max-screen($screen__m) {
    .page-bottom{
        padding-left: 0;
        padding-right: 0;
        .content {
            .block.newsletter {
                margin: 0;
                .content{
                    width: 100%;
                    .control{
                        display: block !important;
                        .subscribe{
                            margin-left: 0 !important;
                            margin-top: 0.5rem;
                        }
                    }
                }
            }
        }
    }
    .page-footer{
        margin-top: 0;
        .footer.content{
            border-top: none;
            padding-top: 5px;
            .full-width{
                .widget.block.block-static-block{
                    margin: 0;
                    justify-content: flex-start;
                    .footer-links-container{
                        display: block;
                        .social-media{
                            display: block;
                            margin-top: 1.25rem;
                            margin-bottom: 0.75rem;
                        }
                    }
                }
            }
        }
    }

    .full-width {
        .widget.block.block-static-block {
            .footer-links-container {
                flex-wrap: wrap;
            }
        }
    }
}

/* TABLET */
@include screen($screen__m, $screen__l) {
    .page-bottom {
        .content {
            .block.newsletter {
                .content {
                    width: 60%;
                }
            }
        }
    }
}
