body #ajaxcart {
    .inner{
        max-width: 40rem;
    }
	.success {
	   background-color: darken($toprom2-green__color, 8%);
	   color: #fff;
	}
    .button-close-top {
        color: #fff;
    }
	.ajaxcart-product-item{
		display: flex;
		flex-direction: column;
		position: relative;
		min-height: 80px;
		text-align: center;
        margin: 1rem 1rem 0rem;
        width: auto;
	}
	.product-image {
		position: absolute;
		left: 0;
		top: 1.5rem;
	}

	.product-name {
		font-weight: bold;
        font-size: 17px;
	}

	.price-box {
        margin: 0.5rem 0rem;
        display: block;
	}

	.option {
		margin: 0.25rem 0;
	}

	.cartamount {
		 background: #ffffff;
		 border-bottom: 1px solid #e3e3e3;
		 display: flex;
		 padding: 1rem 1rem;
		 flex-direction: column;
		 justify-content: center;
		 align-items: center;
			 .remaining-amount {
				 margin-bottom: 10px;
			 }
			 .amount-percent {
				 width: 450px;
				 height: 1rem;
				 -webkit-appearance: none;
					 &::-webkit-progress-bar {
						 background-color: rgba(204, 206, 206, 0.78);
						 border-radius: 10px;
					 }
					 &::-webkit-progress-value {
						 background-color: #3fc353;
						 border-radius: 10px;
					 }
			 }
	 }

    #ajax-slots-wrapper{
        margin-bottom: 1rem;
        .block{
            margin: 0;
            &-actions{
                display: none;
            }
            &-title{
                text-align: center;
                margin-top: 1rem;
                margin-bottom: 0.5rem;
                strong{
                    font-size: 20px;
                    font-weight: 700;
                }
            }
        }
        .products-grid{
            margin: 0;
            .product-items{
                margin: 0;
                .product-item{
                    padding: 0.25rem;
                    padding-left: 0.5rem;
                    min-height: unset;
                    margin: 0.5rem !important;
                    &-photo{
                        width: 100px;
                        height: 100px;
                        margin: 0 auto;
                    }
                    &-name{
                        display: flex;
                        justify-content: center;
                        padding-bottom: 0;
                        margin-top: 0;
                        font-size: 16px;
                    }
                    &-details{
                        .field.choice{
                            display: none;
                        }
                    }
                    .price-box{
                        .price{
                            font-size: 16px;
                        }
                    }
                    &-actions{
                        /* Hidden pr request */
                        display: none;
                        background: transparent;
                        padding: 0.25rem;
                        .action.tocart{
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

	.actions {
        flex-direction: column-reverse;
        align-items: center;
		#button-checkout {
			display: none;
		}
		#button-close {
            width: fit-content;
            border: none;
            background: transparent;
            font-weight: 400;
        }
		#button-cart {
			font-size: 20px;
			font-weight: 500;
            background: $toprom2-cta-button__background-color;
            border: none;
            border-bottom: 2px solid darken($toprom2-cta-button__background-color, 12%);
            width: fit-content;
            color: #fff;
            font-size: 1.2rem;
            margin: 0.5rem 0;
            padding: 0.8rem 2rem;
            &:hover{
                background: darken($toprom2-cta-button__background-color, 2%);
            }
		}
	}
}

/* MOBILE */
@include max-screen($screen__m) {
    body #ajaxcart {
        .inner {
            transform: none;
            top: 0;
            left: 0;

            .success {
               background-color: $toprom2-green__color;
               color: #fff;
            }
            .ajaxcart-product-item {
                display: flex;
                flex-direction: column;
                position: relative;
                padding-left: 6rem;
                min-height: 80px;
                text-align: left;
                width: 90%;
            }
            .product-image {
				margin-right: auto;
				position: absolute;
				left: 0;
                top: 0;
            }

			.product-name {
                font-size: 14px;
			}

            .price-box {
                .price {
                    font-size: 14px;
                }
            }

			.option {
				margin: 0.25rem 0;
			}

            .cartamount {
				 background: #ffffff;
				 border-bottom: 1px solid #e3e3e3;
				 display: flex;
				 padding: 1rem 1rem;
				 flex-direction: column;
				 justify-content: center;
				 align-items: center;
					 .remaining-amount {
						 margin-bottom: 10px;
					 }
					 .amount-percent {
						 width: 315px;
						 height: 1rem;
						 -webkit-appearance: none;
							 &::-webkit-progress-bar {
								 background-color: rgba(204, 206, 206, 0.78);
								 border-radius: 5px;
							 }
							 &::-webkit-progress-value {
								 background-color: $toprom2-cta-button__background-color;
								 border-radius: 5px;
							 }
					 }
			 }

            .actions {
                #button-close {
                    display: none;
                }
                #button-checkout,
                #button-cart {
                    font-size: 18px;
                    font-weight: 500;
                }
            }
        }
    }
}

body.toprode #ajaxcart {
    display: none;
}
