/*
*
* Common Product View Extensions
*
*/

.product-info-main {

    .product-reviews-summary {
        padding: 0;
    }

    .data.table.additional-attributes{
        background-color: $color_lightgrey;
        margin-top: 1.5rem;
        thead,
        tbody{
            tr{
                th,
                td{
                    padding: 1rem;
                    &:nth-child(2){
                        display: none;
                    }
                }
            }
        }
        .toprode &,
        .toprochfr &,
        .toprochde & {
            background: none;
            thead {
                display: none;
            }
            td {
                padding: 0;
            }
            a {
                padding: 1rem;
				background-color: $toprom2-green__color;
				border-color: darken($toprom2-green__color,5%);
				color: #ffffff;
                text-transform: uppercase;
				&:hover {
				    background-color: darken($toprom2-green__color,5%);
                    text-decoration: none;
				}
				&:active {
				    background-color: darken($toprom2-green__color,5%);
				}
            }
        }
    }

    .bundle-actions {
        margin-top: 1.5rem;
    }

}

.product-info-main .box-tocart,
.product-options-bottom .box-tocart {
    width: 100%;

    .input-text.qty {
        border-radius: $polarcore-product-input-text-qty__border-radius;
    }

    .fieldset,
    .actions {
        display: flex;
        width: 100%;
        padding-top: 0;
    }



    .field.qty {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: auto;

        .label {
            display: none;
        }

    }

}

.product-social-links {

    .action.towishlist {

        span {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        span:before {
            @include material-icon($icon-wishlist);
            font-size: 1em !important;
            transform: scale(1.5);
            padding-right: 0.5rem;
        }

    }

}


.product.info.detailed {

    .product.data.items {
    }

    .data.item.title {
        border: 0;
        margin: 1rem 2rem 0;

        .switch {
            position: relative;
			font-size: 20px;
			color: #1a1a1a;
            .counter {
                display: none;
            }
        }

        &.active {
            border-bottom: 3px solid #11525d;
            .switch:after {
                transform: rotate(180deg);
            }
        }

    }

    h2 {
        width: 20%;
    }
    .data.item.content {
        width: 80%;
        display: flex;
        flex-wrap: wrap;
        border: 0;
        margin: auto;
        p{
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
        p,
        span,
        strong{
            line-height: 1.2em;
        }

        &#reviews\.tab{
            margin-top: 0;
        }
        &[aria-hidden="false"] {
            display: block;
        }
    }

    .review-add,
    #product-review-container {
        padding: 1rem;
        width: 100%;
    }

}
/*
*
* Mobile only
*
*/

@include max-screen($screen__l) {
    .product.info.detailed {
        .data.item.content{
            width: 100% !important;
            h2{
                width: 100%;
            }
        }
        #reviews {
            flex-direction: column;
            margin-top: 0;
            .review-add{
                padding-top: 0;
                .action.submit.primary{
                    span{
                        font-size: 14px;
                    }
                }
            }
        }
    }

}

