//
//  Full Bleed row appearance styles
//  _____________________________________________

[data-content-type='row'][data-appearance='full-bleed'] {
    box-sizing: border-box;
    width: calc(100vw - 8px);
    margin-left: calc((100% / 2) - 50vw)!important;

}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m - 1) {
    [data-content-type='row'][data-appearance='full-bleed'] {
        background-attachment: scroll !important; // sass-lint:disable-line no-important
    }
}
