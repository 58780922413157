/* START OF MODULE REWRITES */
.product {
    &-item{
        width: 100%;
        border: none;
        .product-item-actions {
            .action.tocart.primary {
                transition: all 200ms ease;
                background: $color-blue;
                color: #fff;
                border-bottom: none;
                padding: 1rem 2rem;
                width: 100%;
                line-height: normal;
            }
        }
        &-link{
            white-space: normal !important;
            text-overflow: none;
            text-align: left;
        }
        &-details {
            justify-content: space-between;
            &__top {
                display: flex;
                flex-direction: column;
                flex: 1;
            }
        }
        &-name {
            font-size: 18px;
            font-weight: 500;
            display: flex;
            padding-bottom: 10px;
            margin-top: 0.5rem;
        }
        &-description {
            font-size: 14px;
            padding-bottom: 5px;
            padding-right: 0;
            margin: 0;
        }
        &-actions{
            .button-link{
                width: 100%;
            }
        }
        .product-reviews-summary {
            .reviews-actions {
                display: flex;
            }
        }
        .price-box{
            justify-content: flex-start;
            order: 20;
            margin-top: auto;
            margin-bottom: 1rem;
            .exclusive-tax-wrapper{
                order: 0;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                margin-bottom: 0.7rem;
                .extra-text{
                    margin-left: 0.5rem;
                    font-weight: 600;
                }
            }
            .price {
                font-size: 20px;
                font-weight: 700;
            }
        }
        .customergroup-b2b-other-price{
            display: none;
        }
        .action.tocompare {
            display: none;
        }
    }
}
.products {
    margin: 30px -0.5rem;
}

.category-view .page-main h1 {
    margin-top: 7.5rem;
}

/* remove buy button from product list in WPC */
.page-layout-1column{
    .wpcloud-root{
        .product-items{
            padding: 0;
            justify-content: space-between;
            .product-item{
                border-right: 1px solid #c9c9c9;
                width: 24%;
                padding-right: 1rem;
                &-actions{
                    display: none;
                }
                &-link{
                    white-space: normal;
                    text-align: left;
                }
                &:last-of-type{
                    border-right: none;
                }
            }
        }
    }
}

.toolbar-products{
    text-align: left;
    .toolbar-sorter{
        display: none;
    }
}

.products-grid.grid {
    .product-items{
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        margin-left: inherit;
        .product-item{
            display: flex;
            padding: 1rem;
            &-details{
                .vat-relief{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    position: relative;
                    .price{
                        font-size: 20px;
                        font-weight: 700;
                        margin-right: 0.5rem;
                    }
                    .vatRelief{
                        font-size: 14px;
                        margin: 0;
                    }
                    .vatReliefPopup{
                        top: 2.25rem;
                    }
                }
                .vat-relief-other-price{
                    display: none;
                }
            }
            .product-item-info {
                width: 100%;
                display: flex;
                flex-direction: column;
                max-width: 100%;
                .product-item-photo {
                    display: flex;
                    -webkit-box-pack: center;
                    justify-content: center;
                    -webkit-box-align: center;
                    align-items: center;
                }
            }
        }
    }
    .product-item-inner{
        .product-item-actions{
            display: block;
        }
    }
    .toolbar{
        .field.limiter{
            padding-right: 0;
        }
        &-sorter{
            #sorter{
                height: 40px;
                line-height: 20px;
            }
        }
    }
}

.products-grid{
    .products.list.items.product-items{
        .item.product.product-item{
            border-right: 1px solid #c9c9c9;
        }
        .product-item {
            border-bottom: 0;
        }
    }

    .product-item-details {
        display: flex;
        flex-grow: 1;
        flex-direction: column;

        .product-item-inner {
            margin-top: auto;
        }
        &__top{
            // removing b2b category price ref. VITENT2-162
            .b2b-category-price{
                display: none;
            }
        }
    }

    .filter-content {
        .filter-current {
            .items {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                .item {
                    border: 1px solid #5e5e5e;
                    border-radius: 15px;
                    padding-right: 2px;
                    .filter-label {
                        display: none;
                    }

                    .action.remove {
                        position: static;
                        padding-bottom: 2px;
                        padding-right: 5px;
                        &:before {
                            color: #fff;
                            background-color: #474747;
                            border: 1px solid #474747;
                            border-radius: 12px;
                            margin-left: 10px;
                            margin-bottom: 1px;
                            padding-left: 4px;
                            font-size: 18px;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }

    .product-reviews-summary {
        display: flex;
        margin-left: 0.5rem;
        .reviews-actions {
            margin-top: 2px;
        }
    }

    .price-box {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row-reverse;
        .old-price {
            position: initial !important;
            .price {
                font-size: 20px;
                position: initial !important;
                margin-right: 0.5rem;
            }
        }
        .special-price {
            .price {
                font-weight: initial;
            }
        }
    }
}


//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    .category-view .page-main h1 {
        margin-top: 0.5rem;
    }



    .product-item {
        width: 50%;
        .price-box {
            order: 10;
            .price {
                padding-left: 0;
            }
        }
        .product-item-actions {
            order: 20;
            .actions-primary {
                display: flex;
            }
            .action.tocart.primary {
                margin-left: 0;
            }
        }
    }


}

/* Desktop */
@include min-screen($screen__l) {
    .products-grid {
        .product-item {
            width: 30%;
        }
    }
    .page-products {
        .products-grid {
            .product-item {
                width: 33.33%;
                margin-left: 0px;
                .product-item-actions {
                    order: 20;
                    .action.tocart.primary {
                        background: transparent;
                        color: $color-blue;
                        &:hover{
                            background: $color-blue;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}

/* Tablet */
@include screen($screen__m, $screen__l){
    .page-layout-1column{
        .column.main{
            .product-info-main{
                width: 52%;
            }
            .product.media{
                width: 48%;
            }
        }
    }
    .page-products {
        .products-grid {
            .product-item {
                width: 50%;
            }
        }
    }

    .products-grid.grid {
        .product-items.widget-product-grid {
            .product-item{
                width: 33.33%;
                padding: 1rem;
                &:nth-of-type(1n+4){
                    display: none;
                }
                &-details{
                    .vat-relief{
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        position: relative;
                        .price{
                            font-size: 20px;
                            font-weight: 700;
                        }
                        .vatRelief{
                            font-size: 14px;
                        }
                        .vatReliefPopup{
                            top: 2.25rem;
                        }
                    }
                }
            }
        }
    }


}

/* Mobile */
@include max-screen($screen__m) {
    .products-grid.grid {
        .product-items{
            .product-item{

                &-details{
                    .vat-relief{
                        .price{
                            font-size: 14px;
                        }
                        .vatRelief{
                            font-size: 10px;
                            &:after{
                                display: none;
                            }
                        }
                        .vatReliefPopup{
                            top: 2.75rem;
                        }
                    }
                }
            }
        }
    }
    .products-grid{
        .filter {
            .filter-title {
                strong {
                    border-radius: 5px;
                    background-color: #fff;
                    border: 1px solid #474747;
                    border-bottom: 2px solid #474747;
                }
            }
            .filter-content {
                .filter-current {
                    margin-top: 20px;
                }
            }
        }

        .toolbar-products {
            .toolbar-sorter.sorter {
                display: none;
            }
        }

        .product-item-details__top {
            .product-item-name {
                .product-item-link {
                    font-size: 14px;
                }
            }
        }

        .product-item {
            .product-reviews-summary {
                display: flex;
                order: 20;
                padding-left: 0.5rem;
                .rating-summary {
                    margin: 0;
                }
                .reviews-actions {
                    margin-top: 2px;
                    a {
                        font-size: 10px;
                    }
                }
            }
            .price-box {
                .old-price {
                    color: #474747;
                    position: relative;
                    top: 10px;
                }
                .price {
                    font-size: 14px;
                    padding-left: 0;
                }
            }
        }
    }
    .page-layout-1column{
        .wpcloud-root{
            .product-items{
                .product-item{
                    width: 49%;
                    padding-right: 1rem;
                    &:nth-child(even){
                        border-right: none;
                    }
                }
            }
        }
    }
}

