/* header USP styling */
.usp-menu-wrapper, .usp-product-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 1px 1px rgba(0,0,0,0.2);
    width: 100%;
    z-index: 3;
    ul.usp-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 0 auto;
        padding: 0;
        max-width: $layout__max-width;
        li {
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #595959;
            i {
                margin-right: 0.75rem;
            }
        }
    }
}

.usp-menu-wrapper {
    li {
        font-size: 12px;
        font-weight: 500;
		margin: 10px;
    }
}
.usp-product-icons {
    .usp-wrapper {
        .usp-list__item {
            i {
                color: #468966;
            }
        }
    }
}
@include max-screen($screen__m) {
    .usp-product-icons {
        box-shadow: none !important;
        .usp-wrapper {
            .usp-list__item {
                flex-direction: column;
                text-align: center;
                i {
                    margin: 0 !important;
                }
            }
        }
    }
}
