.filter {
    &:not(.active) {
        .filter-subtitle {
            display: none;
        }

        .block-title {
            border-bottom: none;
        }
    }
    &:not(.active) &-title {
        strong {
            width: 100%;
        }
    }
	&-current {
		.action.remove {
			left: unset;
		}
	}
	&-options {
		&-item {
			border: 0;
			border-top: 1px solid #bababa;
		}
	}

    &.active &-title {
        strong {
            margin: 0;
        }
    }
}
