/* Related Products */
.block.related{
    .block-actions{
        font-size: 16px;
    }
    .products-related{
        .product-item{
            box-shadow: 0 0 0 1px #e0e6ee;
            border-right: none !important;
            /* preventing safari bugs */
            -webkit-box-shadow: 0 0 0 1px #e0e6ee;
            -webkit-appearance: none;
            &-info{
                width: 100%;
            }
            &-details__top{
                .price-box{
                    order: 0;
                }
                .field.related{
                    margin-top: 1rem;
                    text-align: left;
                    cursor: pointer;
                }
                .exclusive-tax-wrapper{
                    display: flex;
                    .extra-text{
                        margin-left: 0.4rem;
                        font-weight: 600;
                    }
                }
            }
            &-inner{
                display: none;
            }
            &-link{
				display: -webkit-box;
				width: 100%;
				max-width: 100%;
                margin-top: 0.5rem;
				-webkit-line-clamp: 1;
                /* autoprefixer: off */
				-webkit-box-orient: vertical;
                /* autoprefixer: on */
				overflow: hidden;
				-o-text-overflow: ellipsis;
				text-overflow: ellipsis;
            }
        }
    }
}

/* Upsell */
.block.upsell{
    .column.main & {
        float: none;
    }
    .upsell-heading{
        font-size: 22px;
        color: #1a1a1a;
        font-weight: 500;
    }
    .products-upsell{
        width: 75%;
        margin: 0 auto;
        .product-items{
            display: flex;
            justify-content: center;
            .product-item{
                box-shadow: 0 0 0 1px #e0e6ee;
                -webkit-box-shadow: 0 0 0 1px #e0e6ee;
                -webkit-appearance: none;
                &-info{
                    .product-image-container{
                        z-index: 0;
                    }
                }
            }
        }
    }
}

/* Desktop */
@include min-screen($screen__m){
    /* Related Products */
    .block.related{
        .products-related{
            .product-item{
                width: calc(31.9% - 10px);
                margin: 0 .75rem 1.5rem .75rem;
            }
        }
    }
    /* Upsell */
    .block.upsell{
        .upsell-heading{
            width: 75%;
            margin: 0 auto;
        }
        .products-upsell{
            .product-item{
                width: calc(23.9% - 10px);
                margin: 0 .75rem 1.5rem .75rem;
            }
        }
    }
}

/* Tablet */
@include screen($screen__m, $screen__l){
    .block.related{
        .products-related{
            .product-item{
                width: calc(31.5% - 10px);
            }
        }
    }
    .block.upsell{
        .upsell-heading{
            width: 100%;
            margin: 0;
            padding-left: 1rem;
        }
        .products-upsell{
            width: 100%;
            .product-items{
                flex-direction: row;
                flex-wrap: wrap;
                .product-item{
                    width: calc(23.5% - 10px);
                }
            }
        }
    }
}

/* Mobile */
@include max-screen($screen__m){
    .block.related{
        .products-related{
            .product-item{
                width: calc(50.7% - 10px);
                margin: 0 0.25rem 0.5rem 0.25rem;
            }
        }
    }
    .block.upsell{
        .products-upsell{
            width: 100%;
            margin: 0;
            .product-items{
                flex-direction: row;
                flex-wrap: wrap;
                .product-item{
                    width: calc(49.9% - 10px);
                    margin: 0 0.3rem 0.6rem 0.3rem;
                }
            }
        }
    }
}
