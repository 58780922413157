.old-price,
.old.price {
	color: #000;
}

.product-info-main {
    position: relative;
    top: 4rem;
    .page-title-wrapper {
        .page-title {
            font-size: 32px;
            font-weight: 700;
        }
    }
    .product-info-price{
        display: inline-flex;
        flex-direction: column;
        .price-box{
            margin-top: 0.25rem;
            margin-bottom: 0.5rem;
        }
    }
    .product-info-stock-sku{
        display: inline-flex;
        margin-top: 0rem;
        padding: 0.75rem 0.7rem 1rem;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 0.5rem;
        background-color: $color_lightgrey;
        .configurable-variation-qty {
            display: none;
        }
        .stock{
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            /* Amasty Stock Status */
            .amstockstatus{
                text-indent: initial;
                display: flex;
                align-items: center;
                margin-right: 2.5rem;
                font-weight: 500;
                font-size: 14px;
                margin-top: -2px;
                &:before{
                    content: $icon-checkmark;
                    font-family: "Material Icons";
                    display: block;
                    color: $toprom2-green__color;
                    margin-right: 0.4rem;
                    font-size: 16px;
                    margin-top: 0.1rem;
                }
                &.out-of-stock{
                    &:before{
                        content: "close";
                        color: #FF0000;
                    }
                }
            }
            .amstockstatus_icon{
                display: none;
                height: 10px;
                margin-top: 1px;
                width: 10px;
            }
        }
    }
    .prices-tier {
        .price-container {
            display: inline-block !important;
            .price {
                font-size: 14px !important;
            }
        }
        .item .extra-text {
            display: none;
        }
    }
    .price-box.price-tier_price div {
        width: 100%;
    }
}

.product-info-main,
.product-options-bottom {
    .price-box {
        .price-container{
            display: flex;
            flex-direction: column;
            .price-including-tax{
                order: 2;
            }
            .price-excluding-tax{
                order: 1;
                color: $toprom2-green__color;
                .price{
                    font-size: 28px;
                }
            }
            .price-excluding-tax-container{
                display: flex;
                flex-direction: column;
                align-items: baseline;
                margin-bottom: 0.5rem;
                .price-excluding-tax-vat-container{
                    &-exempt{
                        font-size: 16px;
                    }
                    &-tooltip{
                        margin-left: 1.5rem;
                        font-size: 16px;

                    }
                }
            }
        }
        .price-wrapper .price {
            font-size: 24px;
		}
	}
}

.product-page-info {
    ul {
        padding-left: 0;
        list-style: none;
        margin: 1rem 0 2rem 0;
        li {
            display: flex;
            align-items: baseline;
            &:before {
                content: "";
                display: block;
                height: 10px;
                width: 10px;
                margin-right: 8px;
                background-color: #11525d;
            }
        }
    }
}
.additional-attributes-wrapper{
    table {
        tr {
            &:nth-child(even) {
                background: transparent;
            }
            &:nth-child(odd) {
                background: #f0f0f0;
            }
        }
    }
}
.product-add-form {
    form {
        background-color: $color_lightgrey;
        .product-options-wrapper {
            padding: 0.5rem 1rem;
        }
    }
}

.price-box {
    display: flex;
    flex-direction: row;
    &.price-tier_price{
        margin: 0;
    }
    .price-container {
        &.price-final_price{
            display: flex;
            align-items: baseline;
            flex-direction: row;
        }
        .price-label {
            display: flex;
            font-size: 14px;
            font-weight: 500;
            &:after{
                content: ":";
                display: block;
                margin-right: 0.5rem;
            }
        }
    }
    .old-price{
        .price-container{
            .price-label{
                display: none;
            }
        }
    }
    .special-price{
        margin: 0;
        margin-right: 1rem;
    }
}

.product-info-main {
    position: initial;
    margin-top: 1rem;
    .product-reviews-summary {
        display: flex;
        align-items: center;
        .reviews-actions {
            font-size: 11px;
            line-height: 11px;
        }
    }
}

.review-form-actions{
    .action.submit.primary{
        border-width: 1px 1px 2px 1px;
        border-color: $color_blue;
        background: #fff;
        color: #000;
        transition: all 200ms ease;
        &:hover{
            border: 1px solid $color_blue;
            color: #fff;
            border-width: 1px 1px 2px 1px;
            background: $color_blue;
        }
    }
}

.data.table.additional-attributes {
    tbody {
        tr {
           .col.label {
                border: 1px solid #dedede;
                padding-left: 10px;
            }
            .col.data {
                border: 1px solid #dedede;
                padding-left: 10px;
            }
        }
    }
}

.fotorama-item {
    .fotorama__nav-wrap--vertical {
        margin-top: 2rem;
    }
}
.fotorama__caption {
    display: none;
}

.product-info-main{
    .box-tocart{
        margin-bottom: 0;
    }
    .field.qty {
        margin: 0;
        .control {
            display: flex;
            padding-right: 1rem;
            input,
            button {
                background: #fff;
                height: 52px;
                width: 52px;
                border: 1px solid #E0E6EE;
                padding: 7px 25px 7px 20px;
            }
            input.qty{
                padding: 0;
                width: 52px;
                height: 52px;
            }
            button {
                width: 40px;
                padding: 0;
                border-radius: 0;
               &[data-bind="click: decreaseQty"] {
                    border-right: 0;
               }
               &[data-bind="click: increaseQty"] {
                    border-left: 0;
               }
            }
        }
    }
}

.product-info-main .box-tocart .action.tocart,
.product-options-bottom .box-tocart .action.tocart {
    width: 100%;
    background-color: #ff7600;
    border-color: #cc5e00;
    color: #ffffff;

}
.actions{
    .action.primary.tocart{
        line-height: 10px;
        font-family: "Roboto";
        max-height: 51px;
        background-color: $toprom2-cta-button__background-color;
        border: none;
        border-bottom: 2px solid darken($toprom2-cta-button__background-color, 12%);
        &:hover{
            background-color: darken($toprom2-cta-button__background-color, 2%);
        }
        span{
            text-transform: uppercase;
            font-weight: 500;
            letter-spacing: 1px;
        }
    }
}

.block.upsell {
    #block-upsell-heading {
        display: none;
    }

    .product-items {
       .item.product.product-item {
            border-right: 0 !important;
        }
        .product-item-details__top {
            .product-item-name {
                font-weight: 400;
                font-size: 16px;
                margin-top: 0.5rem;
                margin-bottom: 0.5rem;
            }

            .price-box {
                .special-price {
                    margin: 0;
                }
                .price {
                    font-weight: 500;
                }
            }
        }

        .actions-primary {
            width: 100%;
            .action.tocart.primary {
                border: 1px solid #ff7600;
                border-bottom: 2px solid #ff7600;
                color: #ff7600;
                font-weight: 400;
                transition: all 250ms ease;
                height: 48px;
                margin-top: 0.25rem;
                padding-left: 1rem;
                padding-right: 1rem;
                span{
                    font-weight: 400;
                    font-size: 14px;
                }
                &:hover{
                    background: #ff7600;
                    color: #fff;
                }
            }
        }
    }
}

/* Magiczoom video overlay fix */
.product.media{
    margin-top: 2px;
    .video-selector{
        &:before,
        &:after{
            z-index: 10 !important;
        }
    }
}

.product-page-usp {
    display: flex;
    background-color: $color_lightgrey;
    ul {
        display: flex;
        width: 100%;
        padding-left: 0;
        justify-content: space-around;
        list-style: none;
        margin: 7px 0;
        li {
            position: relative;
            padding-right: 10px;
            font-size: 12px;
            margin: 7px 0;
            .material-icons {
                position: relative;
                top: 4px;
                padding-right: 5px;
                color: $toprom2-green__color;
                font-size: 14px;
            }
        }
    }
}

/* B2B storeview (show price excl. VAT) */
.product-info-main{
    .product-info-price{
        .special-price{
            .price-container{
                .price-label{
                    display: none;
                }
                .price{
                    color: $color_darkgrey;
                }
            }
        }
        .exclusive-tax-wrapper{
            display: flex;
            align-items: center;
            flex-direction: row;
            .price-excluding-tax{
                order: 0;
                color: $color_darkgrey;
                .price{
                    font-size: 24px;
                    color: $color_darkgrey;
                }
            }
            .extra-text{
                margin-left: 0.7rem;
                font-weight: 600;
                font-size: 15px;
            }
        }
        .customergroup-b2b{
            &-other-price{
                display: none;
            }
        }
    }
}

/* VAT (only on UK storeview) */
.price-box.vat-relief {
    align-items: center;
    margin-top: 0;
    .base-price {
        .price {
            color: $color_green;
            font-size: 2em;
            font-weight: 600;
        }
    }
    & > span:not(.base-price) {
        margin: 0 0.5rem;
        font-size: 1.2em;
        display: flex;
        align-items: center;
        &:after {
            @include material-icon('\e88e');
            margin-left: 0.5rem;
            font-size: 1.3rem;
            vertical-align: top;
            color: $toprom2-main__color;
        }
        &:hover {
            cursor: help;
            & + .vatReliefPopup {
                background-size: 100% 100%;
                color: rgba(255,255,255,1);
                height: auto;
                box-shadow: inset 0 1px 2px rgba(0,0,0,0.5);
                transition: all .3s ease-in-out;
            }
        }
    }
    .vatReliefPopup {
        position: absolute;
        padding: 1rem;
        top: 10rem;
        display: inline-block;
        font-size: 1rem;
        color: rgba(255,255,255,0);
        background-image: linear-gradient($toprom2-main__color,$toprom2-main__color);
        background-position: 100% 0;
        background-repeat: no-repeat;
        background-size: 100% 0;
        transition: all .3s ease-in-out;
        width: auto;
        height: 0;
        overflow: hidden;
        z-index: 999;
        border-radius: 0 0 0.2rem 0.2rem;
        .text {
            width: 100%;
        }
    }
    & + .price-final_price {
        order: -1;
        .normal-price {
            .price-container {
                .price-wrapper {
                    .price {
                        font-size: 1.7em;
                    }
                }
            }
        }
    }
}

/* Desktop */
@include min-screen($screen__m){
    .block.upsell{
        .product-items{
            .product-item{
                width: 25%;
                &-info{
                    width: 100%;
                }
            }
        }
    }
}

/* Tablet */
@include screen($screen__m, $screen__l){
    .block.upsell{
        .product-items{
            .product-item{
                width: 33.33%;
            }
        }
    }
}
/* Mobile */
@include max-screen($screen__m) {
   .fotorama-item {
       .fotorama__nav__frame--dot {
          .fotorama__dot {
                width: 10px;
                height: 10px;
            }
       }
   }
  .fotorama__nav__frame--dot.fotorama__active {
        .fotorama__dot {
            background-color: #8c8c8c;
            border-color: #8c8c8c;
        }
   }

   .product.info.detailed {
    	display: flex;
    	justify-content: flex-start;
    	padding-top: 4rem;
		.product.data.items {
			width: 100%;
			.data.item.title {
				width: auto;
			}
		}
   }

   .price-box{
       .price-container{
            .price-label{
                font-size: 12px;
            }
        }
        .old-price{
            top: 18px;
        }
   }

   .box-tocart {
       margin: 0 !important;
        .actions {
            .action.primary.tocart {
            }
        }
   }

   .product-page-usp {
        ul {
            display: flex;
            flex-direction: column;
            padding-left: 1rem;
        }
   }


   .product.attribute.description {
        padding-left: 1rem;
   }

    .product-info-main{
        margin-top: 0;
        .product-info-stock-sku{
            display: block;
            text-align: right;
            margin-top: 0;
            .stock{
                .amstockstatus{
                    margin-right: 0;
                }
            }
            .product.attribute.sku{
                margin: 0.5rem 0rem 2rem;
            }
        }
    }

    .product.info.detailed{
        padding-top: 0;
    }


    .block.upsell {
        .product-items {
            display: flex;
            flex-direction: column;
            .product-item{
                width: 100%;
                .product-item-info{
                    width: 100%;
                }
            }
            .product-item-details {
                .product-item-details__top {
                    .product-item-name {
                        .product-item-link {
                            font-size: 16px;
                            font-weight: 600;
                        }
                    }
                    .price-box {
                        .price {
                            font-weight: 600;
                        }
                    }
                }
            }
            .product-item-actions {
                display: block;
                .actions-primary {
                    .action.tocart.primary {
                        border: 1px solid #ff7600;
                        border-bottom: 2px solid #ff7600;
                        color: #ff7600;
                        margin-left: 0;
                    }
                }
            }
        }
    }
}


@include max-screen($screen__s) {
    .box-tocart {
        .actions {
            .action.primary.tocart {
                font-size: 15px;
            }
        }
    }
}

body.not-logged-in {
    .product-info-main .product-info-stock-sku .stock {
        display: none;
    }
}
