.cart-container{
    table{
        tr:nth-child(even){
            background: #fff;
        }
    }
    #shopping-cart-table{
        .cart.item{
            .col.qty{
                input{
                    width: 45px;
                    height: 35px;
                }
            }
            tr{
                .col.price{
                    text-align: center;
                }
                .col.subtotal{
                    .price-box.vat-relief{
                        justify-content: flex-end;
                    }
                }
                .col.price,
                .col.subtotal{
                    .vat-relief{
                        margin-top: 0.5rem;
                        display: flex;
                        justify-content: center;
                        flex-wrap: wrap;
                        line-height: 32px;
                        position: relative;
                        .price{
                            font-size: 18px;
                            font-weight: 700;
                        }
                        .vatRelief{
                            font-size: 14px;
                        }
                        .vatReliefPopup{
                            top: 4rem;
                            width: 35vw;
                        }
                    }
                    .vatRelief-checkout-description{
                        font-size: 14px;
                        white-space: normal;
                        margin: 0;
                    }
                }
            }
            .price-excluding-tax{
                display: inline-flex;
            }
            .extra-text{
                display: inline-flex;
                font-size: 14px;
                font-weight: 600;
                margin-left: 0.3rem;
                vertical-align: top;
            }
            .product-item-details{
                .item-options{
                    &:last-of-type{
                        margin-bottom: 0;
                    }
                }
                .sku-container{
                    display: flex;
                    align-items: center;
                    strong{
                        margin-right: 10px;
                        font-size: 12px;
                    }
                    p{
                        margin: 0;
                        font-size: 12px;
                    }
                }
            }
        }
        thead{
            .col.price{
                text-align: center;
            }
        }
    }
    .cart-summary{
        #block-shipping{
            display: none;
        }
        .summary.title{
            display: none;
        }
        .cart-totals{
            padding-top: 0;
            .grand.totals{
                td,
                th{
                    padding: 10px 0px;
                }
            }
        }
        .block.active{
            margin-bottom: 1.5rem;
        }
        .block{
            margin-bottom: 35px;
            strong{
                margin: 0;
                font-weight: 600;
            }
            #block-shipping-heading{
                padding-bottom: 0;
            }
            #block-summary{
                .legend{
                    display: none;
                }
            }
        }
        .block.discount{
            margin-bottom: 20px;
        }
        .checkout{
            .checkout{
                background-color: $toprom2-green__color;
                border: none;
                border-bottom: 2px solid darken($toprom2-green__color, 8%);
                &:hover{
                    background: darken($toprom2-green__color, 2%);
                }
            }
        }
    }
    #gift-options-cart,
    #block-giftcard{
        display: none;
    }
}

/* Tablet only */
@include screen($screen__m, $screen__l){
    .cart.table-wrapper{
        .item{
            .col.item{
                width: 265px;
            }
        }
    }
}

/* Mobile only */
@include max-screen($screen__m){
    .cart-container{
        #shopping-cart-table{
            .cart.item{
                .price-excluding-tax{
                    display: block;
                }
                .extra-text{
                    display: block;
                    margin-top: 0.3rem;
                }
            }
            thead{
                .col.price{
                    text-align: center;
                }
            }
            tbody{
                .col.subtotal{
                    .vat-relief{
                        justify-content: center !important;
                    }
                }
            }
        }
    }
}
