.block-search {
    .block-content {
        .minisearch {
            .search {
                margin-top: 16px;

                .control {
                    .input-text {
                        border: none;
                        border-bottom: 1px solid $toprom2-gray__color;
                        margin-top: 2px;
                    }

                    .search-autocomplete {
                        &#minisearch-autocomplete-top-search {
                            width: 600px !important;
                            display: block !important;
                        }

                        width: 100%;
                        box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.2);
                        margin: 0px auto 50px;
                        background-color: $color-white;

                        .smile-elasticsuite-autocomplete-result {
                            .autocomplete-list {
                                &:first-child {
                                    display: none;
                                }

                                .title-product {
                                    display: none;
                                }

                                [role="option"] {
                                    padding: 15px;
                                    display: flex;
                                    cursor: pointer;

                                    &:hover {
                                        background-color: #f2f2f2;
                                    }

                                    .product-shop {
                                        margin-left: 25px;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                        overflow: hidden;

                                        .price {
                                            font-size: 0.9rem;
                                            font-weight: 600;
                                            height: 22px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .actions {
                .search {
                    margin-top: 16px;

                    :before {
                        width: 19px;
                        height: 19px;
                    }
                }
            }
        }
    }
}

@include max-screen($screen__m) {
    .block-content {
        .minisearch {
            .search {
                .label {                   
                    position: absolute;
                     right: 7px;
                     top: -6px !important;

                    &:before {
                        font-size: 26px;
                    }
                }

                .control {
                    margin-top: -10px;
                    .input-text {
                        margin: 0px;
                    }
                }
            }
        }
    }
}
