//
//  Default appearance styles
//  _____________________________________________

div[data-content-type='text'] {
    word-wrap: break-word;
    h1, h2 {
        font-family: "Adobe Garamond Pro Regular";
    }
}
