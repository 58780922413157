$red-color: #ed1930;
$main-color: #1c5275;

body{
    /* Will contain algolia styles */
    #algolia-searchbox {
        input#search {
            border-top: none;
            border-right: none;
            border-bottom: 1px solid $toprom2-gray__color;
            border-left: none;
            background-color: transparent !important;
        }
        .clear-cross {
            bottom: 9px;
            width: 24px;
            height: 24px;
            right: 13px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
            background-position: center;
            background-repeat: no-repeat;
            opacity: 0;
            transition: opacity 400ms;
            &.visible{
                opacity: 1;
                display: block;
            }
        }
        #algolia-glass {
            bottom: 11px;
            right: 13px;
            transition: opacity 400ms;
            opacity: 1;
            &.hidden{
                opacity: 0;
            }
        }
    }
    #algolia-autocomplete-container {
        .aa-dropdown-menu {
            min-width: unset;
            max-width: 600px;
            border-top: none;
            border: none;
            box-shadow: 0px -2px 8px 0px rgba(0,0,0,0.2);
            .col9 {
                width: 100%;
                border-left: none;

                .aa-dataset-products {
                    .aa-suggestions{
                        margin: 0px auto 50px;
                    }
                    .aa-suggestion {
                        width: 100%;

                        .algoliasearch-autocomplete-hit {
                            padding: 15px;

                            .info {
                                margin-left: 70px;

                                div.algoliasearch-autocomplete-category {
                                    white-space: normal;
                                }

                                .algoliasearch-autocomplete-price {
                                    color: #434343;
                                    font-size: 0.9rem;
                                    font-weight: 600;
                                }
                            }
                        }
                    }
                }

                #autocomplete-products-footer {
                    width: 100%;
                    left: 0;
                }
            }
        }
    }

    .algolia-instant-results-wrapper {
        margin-bottom: 150px;
        .instant-search-bar-label {
            background: none;
            border-bottom: 2px solid $main-color;
            width: 40px !important;
        }

        .ais-search-box--magnifier svg {
            fill: $main-color;
            stroke: $main-color;
            left: 5px !important;
        }

        #instant-search-bar {
            border: none;
            border-bottom: 2px solid $main-color;
            width: calc(100% - 40px) !important;
        }

        .ais-range-slider {
            .rheostat-horizontal {
                .rheostat-progress {
                    background-color: $red-color;
                }
            }
        }
        #algolia-left-container {
            #instant-search-facets-container {
                .facet {
                    border: none;
                    border-top: 1px solid #bababa;
                    padding: 10px;
                    .ais-header {
                        .name {
                            font-weight: 400;
                            margin: 0;
                            overflow: hidden;
                            padding: 10px 40px 0 10px;
                            position: relative;
                            text-transform: uppercase;
                            word-break: break-all;
                            z-index: 1;
                            color: #666666;
                            display: block;
                            text-decoration: none;
                            background-color: transparent;
                        }
                    }
                }
            }
        }
        #instant-search-results-container {
            .ais-hits {
                .ais-hits--item {
                    > div {
                        padding: 0;
                    }
                }
            }
            .result-wrapper {
                .result-thumbnail {
                    img {
                        width: 80%;
                        height: auto;
                    }
                }
                a.result {
                    border: none;
                    border-right: 1px solid #c9c9c9;
                    padding-bottom: 6px;
                    h3.result-title{
                        color: $color-black;
                        font-size: 18px;
                        font-weight: 500;
                        text-align: left;
                    }
                    .price {
                        color: $color-black;
                        font-size: 20px;
                        font-weight: 700;
                        text-align: left;
                    }
                    .product-reviews-summary {
                        display: none;
                    }
                }
                a.button-link {
                    button {
                        margin: 0 auto;
                        margin-top: -5px;
                        margin-bottom: 10px;
                        width: 90%;
                        padding: 1rem 2rem;
                        background: transparent;
                        color: #1c5275;
                        text-transform: uppercase;
                        font-weight: 500;
                        font-size :16px;
                        &:hover {
                            background: #1c5275;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
    // Remove prices on INT, SE, DK and NL storeviews
    &.int,
    &.topronl,
    &.toprose,
    &.toprodk,
    &.topronlfr{
        .autocomplete-wrapper{
            .algoliasearch-autocomplete-price{
                display: none;
            }
            .aa-suggestion{
                padding: 0.5rem 0rem;
                .algoliasearch-autocomplete-hit{
                    padding-bottom: 2.5rem !important;
                    padding-top: 0.2rem !important;
                    .info{
                        white-space: normal !important;
                    }
                }
            }
        }
        #instant-search-results-container{
            .ais-hits--item{
                .price-wrapper{
                    display: none;
                }
            }
        }
        .product-info-main{
            .data.table.additional-attributes{
                display: none;
            }
        }
    }
}

// Small Tablet & Mobile
@include max-screen($screen__m + 2px) {
    body{
        .algolia-search-block{
            .block-content{
                margin-top: 0;
                #algolia-searchbox {
                    height: auto;
                    overflow: hidden;
                    input#search {
                        max-height: unset;
                        margin: 0;
                    }
                }

                #algolia-autocomplete-container {
                    .aa-dropdown-menu {
                        right: 7px !important;
                        width: auto !important;
                    }
                }
            }
        }
    }
}
