//
//  Variables
//  _____________________________________________

$checkout-wrapper__margin             : $indent__base !default;
$checkout-wrapper__columns            : 8 !default;

$checkout-step-title__border          : $border-width__base solid $color-gray80 !default;
$checkout-step-title__font-size       : 26px !default;
$checkout-step-title__font-weight     : $font-weight__light !default;
$checkout-step-title__padding         : $indent__s !default;

$checkout-step-title-mobile__font-size: 16px !default;

//
//  Common
//  _____________________________________________

.checkout-index-index,
.checkout-onepage-success {
    .page-title-wrapper {
        @extend .abs-visually-hidden;
    }

	.page-wrapper {
		background: #f9f9f9;
	}

    .checkout-success {
        width: 300px;
        height: 300px;
        background: #ffffff;
        padding: 3rem;
        text-align: center;
        margin: 4rem auto;
        box-shadow: 0 0 12px 0 rgba(0,0,0,0.1);

        .actions-toolbar {
            padding: 1rem;
            text-align: center;
            justify-content: center;
        }
    }

    #reference-number-form .input-text {
        width: 180px;
    }
}

.checkout-container {
    @extend .abs-add-clearfix;
    margin: 0 0 $checkout-wrapper__margin;
}

.opc-wrapper {
    margin: 0 0 $checkout-wrapper__margin;

    .opc {
    }

    .step-title {
        @extend .abs-checkout-title;
		font-family: $font-family__base;
    }

    .step-content {
        margin: 0 0 $indent__xl;
    }
}

.checkout-index-index {
    .nav-sections,
    .nav-toggle {
        display: none;
    }

    .logo {
        margin-left: 0;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .opc-wrapper {
        .step-title {
            font-size: $checkout-step-title-mobile__font-size;
            border-bottom: 0;
            padding-bottom: 0;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-wrapper {
		display: flex;

		.opc {
			display: flex;
			margin: 0;
			padding: 0;
			flex-wrap: wrap;
			width: 100%;

			& > li {
				list-style: none;
				width: calc(50% - 2rem);
				padding: 2rem;
				margin: 1rem;
				background: #ffffff;
			}
		}
    }

	.checkout-container {

		.opc-summary-wrapper {
			margin: 1rem 1rem;
			width: calc(100% - 2rem);
		}

		.opc-block-summary {
			background: #ffffff;
		}
	}
}

