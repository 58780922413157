.page-header {
    border: none;
    z-index: 99;
    background-color: transparent;
    transition: all 0.5s ease;

    // Set white background on scroll past menu
    &.header-background {
        background-color: #ffffff;
        position: fixed;
        width: 100%;
        animation: smoothScroll 500ms forwards;
        padding-bottom: 0.25rem;
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
        top: 0px;
    }

    .widget.block {
        margin: 0;
    }
}

// Header animation
@keyframes smoothScroll {
    0% {
        transform: translateY(-40px);
    }

    100% {
        transform: translateY(0px);
    }
}

/* breadcrumbs */
.page-wrapper {
    .breadcrumbs {
        width: 100%;
        max-width: 100%;
        background: $color_lightgrey;
        margin-bottom: 1rem;
        padding: 0.25rem;

        .items {
            width: 100%;
            margin: 0 auto;
            padding: 1.25rem 0.25rem;
            display: flex;
            align-items: center;

            > li {
                font-size: 12px;
                line-height: 1;
                display: inline-flex;
                align-items: center;
                padding-right: 0.25rem;

                &:last-of-type {
                    strong {
                        padding: 0;
                    }
                }
            }
        }
    }
}
.header.content {
    padding: 0.5rem 0;

    .logo {
        order: 1;
        padding-bottom: 0.5rem;
        max-width: 225px;
    }

    .header-links-static-block {
        display: flex;
        order: 4;

        ul {
            display: flex;
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                padding-left: 1.5rem;
                padding-right: 1.5rem;
                border-right: 2px solid $toprom2-gray__color;
                margin: 0;
                line-height: 1.4;

                a {
                    font-size: 18px;
                    color: $color_black;
                    padding: 0rem 0.75rem;
                    vertical-align: middle;
                }
            }
        }
    }

    .block.block-search {
        order: 2;
        margin-left: auto;
        margin-right: 1rem;
        max-width: 325px;
        width: 100%;

        .input-text {
            height: auto;
            padding: 0.75rem;
        }

        #minisearch-input-top-search {
            background-color: transparent;
        }

        .control {
            border: none;

            input {
                &:focus {
                    box-shadow: none;
                }
            }
        }

        .actions {
            button.action.search {
                opacity: 1 !important;

                &:before {
                    color: #595959;
                    line-height: unset;
                }
            }
        }
    }

    .header-links-meganav {
        order: 3;
        padding-left: 2.25rem;
        padding-right: 2.25rem;
        border-left: 2px solid $toprom2-gray__color;
        border-right: 2px solid $toprom2-gray__color;

        > ul {
            padding: 0;
            margin: 0;

            > li {
                margin: 0;
                line-height: 1.4;

                a:after {
                    line-height: 0;
                }
            }
        }

        .meganav-wrapper {
            border-top: none;
        }
    }

    .polarcore-header-links {
        order: 5;
        z-index: 9;

        .wishlist-link {
            display: none;
        }

        .account-link {
            display: none;
        }
    }

    .minicart-wrapper {
        padding-left: 1rem;
        margin: 0;

        .action.showcart {
            display: flex;
            flex-direction: unset;
            padding-right: 6px;

            &:before {
                width: 27px;
                height: 27px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 27' %3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: center;
                content: '';
            }

            .text {
                display: none;
            }

            .counter.qty {
                &.empty {
                    display: none;
                }

                display: flex;
                top: -8px;
                right: 2px;
                border-radius: 4px;
                width: 17px;
                height: 17px;
                background: $color_green;
                transform: unset;

                .counter-number {
                    display: flex;
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                    margin-top: 0.25rem;
                    font-size: 12px;
                }
            }
        }
    }
}

#tooltip {
    top: 3rem;
}

// Laptop
@include screen($screen__l, $screen__l + 234px) {
    body {
        .header.content {
            .logo {
                img {
                    width: 18vw;
                }
            }

            .header-links-static-block {
                ul {
                    li {
                        padding-right: 1.1rem;
                        padding-left: 1.1rem;

                        a {
                            font-size: 16px;
                            padding: 0.6rem;
                        }
                    }
                }
            }

            .header-links-meganav {
                padding-right: 1.6rem;
                padding-left: 1.6rem;

                .meganav-trigger {
                    font-size: 16px;
                }
            }
        }
    }
}


// Tablet + Laptop
@include screen($screen__m, $screen__l + 234px) {
    .panel.header {
        .switcher {
            margin-right: 0;
        }
    }

    .header.content {
        display: flex;
        justify-content: flex-end;

        .logo {
            margin-right: auto;

            img {
                width: 14vw;
            }
        }

        .header-links-static-block {
            ul {
                li {
                    padding-left: 0.8rem;
                    padding-right: 0.8rem;

                    a {
                        font-size: 13px;
                        padding: 0.3rem;
                    }
                }
            }
        }

        .header-links-meganav {
            padding-right: 1.3rem;
            padding-left: 1.3rem;

            ul > li {
                line-height: 1.3;
            }

            .meganav-trigger {
                font-size: 13px;
                vertical-align: middle;

                &:after {
                    font-size: 19px;
                }
            }
        }

        .block.block-search {
            max-width: 23vw;
            margin-left: 0.5rem;
            height: auto;
            min-height: unset;
            margin-right: 0;

            #algolia-searchbox {
                #algolia-glass {
                    bottom: 6px;

                    &:before {
                        width: 19px;
                        height: 19px;
                    }
                }

                .clear-cross {
                    bottom: 8px;
                    width: 19px;
                    height: 19px;
                }

                input#search {
                    height: 34px;
                }
            }
        }

        .minicart-wrapper {
            .action.showcart {
                padding-left: 0.5rem;
                padding-right: 0;

                &:before {
                    width: 22px;
                    height: 22px;
                }
            }
        }
    }
}


/* MOBILE */
@include max-screen($screen__m) {
    /* breadcrumbs */
    .page-wrapper {
        .breadcrumbs {
          margin-top: 20px;
            .items {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    }

    #maincontent {
        padding-top: 0.5rem;
    }

    .header.content {
        .header-links-static-block {
            display: none;
        }

        .minicart-wrapper {
            .action.showcart {
                padding-right: 8px;
            }
        }

        .block.block-search {
            width: 100%;
            max-width: none;
            margin-right: 0;
            margin-top: 0;
            min-height: 43px;

            .control {
                #search {
                    background: transparent;
                    padding: 0;
                    padding: 1.25rem;
                    margin: auto 0.25rem;
                }
            }
            .field.search {
                label {
                    top: 3px !important;
                }
            }
        }
    }
}
