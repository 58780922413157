.page-header,
.page-footer {
    .switcher {
        color: $toprom2-cod-grey__color;
        margin-left: 2.5rem !important;
        .options {
            @include lib-dropdown(
                    $_dropdown-list-position-right       : -1.55em,
                    $_dropdown-list-item-hover           : transparent,
                    $_dropdown-list-border               : none,
                    $_dropdown-list-pointer              : false,
                    $_dropdown-list-margin-top           : 0.39rem,
                    $_dropdown-actions-padding           : 0,
                    $_dropdown-list-item-padding         : 0rem,
                    $_dropdown-toggle-icon-content       : $icon-down,
                    $_dropdown-toggle-active-icon-content: $icon-up,
                    $_icon-font-text-hide                : true,
                    $_icon-font-size                     : 22px,
                    $_icon-font-line-height              : 22px,
                    $_dropdown-list-width                : calc(100% + 1rem + 2em),
                    $_dropdown-list-min-width            : unset
            );
            ul.dropdown {
                &:before,
                &:after{
                    display: none;
                }
                .switcher-option{
                    border-bottom: 1px solid #f0f0f0;
                    &:hover {
                        background-color: #f7f7f7;
                    }
                }
                a {
                    display: flex;
                    align-items: center;
                    padding: 0.8rem 1.5rem;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        li {
            margin: 0;
        }
        .switcher-label {
            display: flex;
            align-items: center;
            cursor: pointer;
            width: 100%;
            clip: unset;
            overflow: visible;
            position: static;
            height: auto;
            margin: 0;
            span {
                margin: 0.2rem 0.25rem 0 0.5rem;
            }
        }
        .label {
            &:after {
                @include material-icon($icon-arrow_drop_down);
                font-size: 1.5em;
                line-height: 1.25;
                transition: transform .5s ease;
            }
            &.open {
                position: relative;
                & + .switcher {
                    &-options {
                        color: $toprom2-cod-grey__color;
                        width: 100%;
                        ul {
                            display: block;
                            margin-top: 0.65rem;
                            li span {
                                color: $toprom2-cod-grey__color;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
                &:after {
                    transform: rotate(180deg);
                }
            }
        }
        img {
            max-height: 17px;
            box-shadow: 0 0 0 1px #ffffff;
        }
        .ch {
            img {
                margin: 0 5px;
            }
        }
    }
    // Language switcher on swiss storeview
    .switcher-language {
        margin-right: 1rem;
    }
}

/* Tablet */
@include screen($screen__m, $screen__l){
    .page-header{
        .switcher{
            margin-right: 1.4rem;
        }
        .right-side{
            ul{
                padding-left: 0rem;
            }
        }
    }
}

/* Mobile */
@include max-screen($screen__m){
    .page-header{
        .panel.wrapper{
            .panel.header{
                padding: 0;
                .switcher{
                    .label{
                        margin: 0;
                        font-size: 12px;
                    }
                }
                // Store switcher
                .switcher-store{
                    display: block;
                    position: absolute;
                    top: 0.65rem;
                    right: 8.5rem;
                    z-index: 100;
                }
                // Language switcher (Swiss, Dutch)
                .switcher-language{
                    display: block;
                    position: absolute;
                    right: 0.5rem;
                    top: 0.65rem;
                    margin: 0;
                    z-index: 100;
                }
            }
        }
    }
}
