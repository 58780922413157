$default_font: 'Myriad Pro Regular';

@font-face {
	font-family: 'Myriad Pro Regular';
	font-style: normal;
	font-weight: normal;
	src: local('Myriad Pro Regular'), url('../fonts/MYRIADPRO-REGULAR.woff') format('woff');
}

@font-face {
	font-family: 'Adobe Garamond Pro Regular';
	font-style: normal;
	font-weight: normal;
	src: local('Adobe Garamond Pro Regular'), url('../fonts/AGaramondPro-Regular.woff') format('woff');
}

@font-face {
	font-family: 'Adobe Garamond Pro Italic';
	font-style: normal;
	font-weight: normal;
	src: local('Adobe Garamond Pro Italic'), url('../fonts/AGaramondPro-Italic.woff') format('woff');
}

// Elements using fonts ref. TOPRO1-252
body{
    h1{
        font-family: $default_font;
    }
    // WPC Main Banner Headline
    .wpcloud-root{
        .wpcloud-banner2__headline{
            font-family: "Adobe Garamond Pro Regular";
        }
    }
    h2{
        font-family: $default_font;
    }
    h3{
        font-family: $default_font;
    }
    h4{
        font-family: $default_font;
    }
}

// Desktop
@include min-screen($screen__l + 1px){
    body{
        h1{
            font-size: 60px;
        }
        h3{
            font-size: 30px;
        }
    }
}

// Tablet
@include screen($screen__m, $screen__l){
    body{
        h1{
            font-size: 40px;
        }
        h3{
            font-size: 25px;
        }
    }
}

// Mobile
@include max-screen($screen__s){
    body{
        h1{
            font-size: 25px;
        }
        h3{
            font-size: 20px;
        }
    }
}
