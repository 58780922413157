.product-reviews-summary {
    .reviews-actions {
        a {
            color: #a3a3a3;
            &:hover {
                text-decoration: none;
            }
            &:visited {
                color: #a3a3a3;
                text-decoration: none;
            }
        }
        .action.add {
            display: none;
        }
    }
}
//
//  Desktop
//  _____________________________________________

@include min-screen($screen__s) {
    .review {
        &-form {
        	max-width: unset;
		}
	}
}
