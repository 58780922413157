.message.company-warning {
    display: none;
}

.page-header {
    box-shadow: none;
    background-color: transparent;
    .panel.wrapper {
        background-color: transparent;
        border: none;
        .panel.header {
            padding: 0.75rem 1rem 0.25rem;
            .widget.block.block-static-block {
                margin-bottom: 0;
                margin-top: 0;
                width: 100%;
                display: flex;
                justify-content: flex-end;
                .header-usp {
                    .left-side {
                        display: flex;
                        ul {
                            display: flex;
                            list-style: none;
                            padding-left: 0;
                            margin: 0 0 0 1rem;
                            li {
                                display: flex;
                                align-items: center;
                                padding-right: 0.5rem;
                                font-size: 13px;
                                &:first-of-type {
                                    font-weight: 900;
                                    a {
                                        color: inherit;
                                    }
                                }
                                a {
                                    margin-left: 0.25rem;
                                }
                            }
                        }
                    }

                    .right-side {
                        display: flex;
                        margin-left: auto;
                        ul {
                            display: flex;
                            list-style: none;
                            padding-right: 1rem;
                            margin: 0;
                            li {
                                margin: 0;
                                &:first-of-type {
                                    a {
                                        display: flex;
                                        align-items: center;
                                        line-height: 1;
                                        &:before {
                                            margin-right: 5px;
                                            @include material-icon('person');
                                        }
                                    }
                                }
                                a {
                                    color: #595959;
                                    text-decoration-line: none;
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include max-screen($screen__m) {
    .page-header {
        .panel.wrapper {
            .panel.header {
                .widget.block.block-static-block {
                    .header-usp {
                        display: none;
                    }
                }
            }
        }
    }
}
