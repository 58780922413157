//
//  Default appearance styles
//  _____________________________________________

[data-content-type='heading'] {
    word-wrap: break-word;
    font-family: "Adobe Garamond Pro Regular";
    & h1 {
        font-size: 50px;
    }
}

.panel .header [data-content-type='row'][data-appearance='contained'] {
    margin-left: initial !important;
    margin-right: initial !important;
}
