.cms-index-index {
    .columns .column.main {
        margin-top: -4px;
        .tocart{
            background: #fff;
            color: #000;
            border-width: 1px 1px 2px 1px;
            border-color: $color-blue;
        }
    }
}
.columns .column.main {
    padding-bottom: 10rem;
}

.field-tooltip {
    span#tooltip-label {
        display: none;
    }
}

body{
    button,
    a.button{
        text-transform: none;
    }
}

/* Remove price filter on INT, SE, DK & NL storeviews */
body{
    &.int,
    &.topronl,
    &.toprose,
    &.toprodk,
    &.topronlfr{
        #layered-filter-block .filter-options .price{
            display: none;
        }
    }
}

/* weird scrolling issue (mainly mobile) */
.page-products{
    overflow-x: hidden;
}

@media screen and (max-width: $screen__m - 1) {
    .hide_on_phone {
        display: none;
    }
}
@media screen and (min-width: $screen__m) and (max-width: $screen__l - 1) {
    .hide_on_tablet {
        display: none;
    }
}
@media screen and (min-width: $screen__l) {
    .hide_on_desktop {
        display: none !important;
    }
}

/* Mobile */
@include max-screen($screen__m){
    #maincontent{
        .columns{
            .column.main{
                padding-bottom: 2rem;
            }
        }
    }
}
