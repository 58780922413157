// Colors
$pf-darkgreen: #2a8039;
$pf-green: #37a447;
$pf-lightgreen: #c5f0bd;
$pf-lightgrey: #f0f0f0;
$pf-red: #ed1b2f;
$pf-white: #ffffff;

.productfinder {

    &__filter {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin: 40px;
        padding: 24px 300px 24px 200px;
        border-radius: 500px;
        background: $pf-lightgrey;

        &--pf_age {
            background-image: url(../Trollweb_ProductFinder/images/pf_age.svg);
            background-repeat: no-repeat;
            background-position: right 80px center;
        }
        &--pf_weight {
            background-image: url(../Trollweb_ProductFinder/images/pf_weight.svg);
            background-repeat: no-repeat;
            background-position: right 80px center;
        }
        &--pf_height {
            background-image: url(../Trollweb_ProductFinder/images/pf_height.svg);
            background-repeat: no-repeat;
            background-position: right 80px center;
        }
        &--pf_environment {
            background-image: url(../Trollweb_ProductFinder/images/pf_environment.svg);
            background-repeat: no-repeat;
            background-position: right 80px center;
        }
        &--pf_purpose {
            background-image: url(../Trollweb_ProductFinder/images/pf_purpose.svg);
            background-repeat: no-repeat;
            background-position: right 80px center;
        }
        &--pf_important {
            background-image: url(../Trollweb_ProductFinder/images/pf_important.svg);
            background-repeat: no-repeat;
            background-position: right 80px center;
        }
	}

    &__counter {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        height: 100%;
        width: 120px;
        margin-left: 20px;

        span {
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 3;
            height: 120px;
            width: 120px;
            border-radius: 50%;
            background: $pf-lightgreen;
            font-size: 30px;
        }

        &:after {
            content: "";
            position: absolute;
            left: 40px;
            top: -20px;
            z-index: 2;
            display: flex;
            width: 40px;
            height: calc(100% + 40px);
            background: $pf-lightgreen;
        }
    }

    &__filter:first-of-type &__counter:after {
        top: 50%;
        height: calc(50% + 20px);
    }

    &__search-and-results-wrapper &__counter {
        width: 150px;
        margin-left: 5px;

        span {
            width: 150px;
            height: 150px;
            color: $pf-white;
            background: $pf-darkgreen;
        }

        &:after {
            left: 55px;
            bottom: 50%;
            height: calc(50% + 20px);
        }
    }

    &__filter-title {
        width: 100%;
		margin: 0 0 12px;
        font-size: 30px;
        font-weight: normal;
    }

	&__filter-subtitle {
		width: 100%;
	}

    &__filter-option {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

		// Because I won't use !important, I won't do it! NO!
		.productfinder & {
	        margin: 12px 12px 0 0;

            &:last-of-type {
	            margin: 12px 0 0 0;
            }
        }

        input {
			position: absolute;
			visibility: hidden;
			left: -1000px;

			&:checked + label {
				color: $pf-white;
				background-color: $pf-green;
			}
		}

        label {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: 100px;
			width: 100px;
		    box-shadow: 0px 1px 5px rgba(0,0,0,0.35);
			border-radius: 5px;
			padding: 2px;
			text-align: center;
		    font-size: 20px;
		    background: $pf-white;

			&:hover {
				cursor: pointer;
			}
        }

		&--checkbox {

			label {
				justify-content: flex-start;
				align-items: flex-start;
				text-align: left;
				font-size: 16px;

				&:before {
					content: "check_box_outline_blank";
				    font-family: Material Icons;
				    font-size: 30px;
					line-height: 1;
				}
			}

			input:checked + label:before {
				content: "check_box";
			}
		}

        &--has-swatch {
            label {
                width: 200px;
                padding-right: 100px;
                background-repeat: no-repeat;
                background-position: right center;
            }
        }
	}
    &__search-and-results-wrapper {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        min-height: 160px;
        margin: 40px;
        padding: 24px 24px 24px 200px;
        //border-radius: 500px;
        //background: $pf-lightgrey;
    }

    &__button {

        &--search {
            width: 100%;
            height: 60px;
            border-radius: 30px;
            color: $pf-white;
            background: $pf-darkgreen;
            font-size: 30px;

            &:hover,
            &:active,
            &:focus {
                color: $pf-white;
                background: $pf-darkgreen;

            }
        }

        &--gotoproduct {
            height: 60px;
            padding: 10px 40px;
            color: $pf-white;
            background: $pf-red;
            font-size: 24px;
            font-weight: normal;

            &:hover {
                cursor: pointer;
            }

            &:hover,
            &:active,
            &:focus,
            &:visited {
                color: $pf-white;
                background: $pf-red;
                text-decoration: none;
            }
        }
    }

    &__validation-errors {

        ul {
            margin: 0;
            padding: 0;
        }
    }

    &__result-title {
        font-weight: normal;
    }

    &__result-list {
        margin: 40px 0 0;
        padding: 0;
        list-style-type: none;
    }

    &__result-item {
        display: flex;
        flex-direction: row;
        margin: 20px 0;
    }

    &__result-item-details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-basis: 60%
    }

    &__result-item-image-wrapper {
        display: flex;
        align-items: flex-start;
    }

    &__result-item-name {
        font-size: 30px;
        font-weight: normal;
        margin-bottom: 20px;
    }

    &__result-item-description {
        margin: 20px;
    }
}
