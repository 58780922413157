.block.filter{
	border-top: none;
	margin-bottom: 1rem;
    .filter-actions{
        display: none;
    }
    .filter-content{
        .filter-options{
            &-item{
                border-bottom: 1px solid #bbb;
                border-top: none;
                border-left: none;
                border-right: none;
                padding: 0.75rem 1rem;
                margin-bottom: 0;
            }
            &-title{
                cursor: pointer;
				font-weight: 400;
				margin: 0;
                font-size: 18px;
				overflow: hidden;
				padding: 10px 40px 0 0;
				position: relative;
				text-transform: capitalize;
				word-break: break-all;
				z-index: 1;
				white-space: nowrap;
				padding-bottom: .5rem;
				display: block;
				text-decoration: none;
                &:after{
                    top: 0;
                    right: -7px;
                }
            }
            &-content{
                padding: 0;
                .item{
                    a{
                        padding-left: 0;
                        font-size: 14px;
                        margin: 0;
                        color: $toprom2-cod-grey__color;
                        .label{
                            margin-right: 2px;
                        }
                        .count{
                            white-space: nowrap;
                        }
                    }
                    input{
                        -webkit-appearance: button;
                        border-radius: 2px;
                        border: 1px solid $toprom2-cod-grey__color;
                        cursor: pointer;
                        margin-top: -2px;
                        vertical-align: middle;
						background: #ffffff;
						width: 1rem;
						height: 1rem;
                        &:hover{
                            background: #eaeaea;
                        }
                        &:checked{
							position: relative;
                            &:before{
                                content: "✔";
								position: absolute;
							    color: #fff;
							    font-size: 8px;
							    top: -4px;
							    left: 3px;
                            }
                        }
                    }
                }
                .ui-slider{
                    height: 5px;
                    &-range{
                        background: #666;
                        height: 5px;
                    }
                    &-handle{
                        margin-top: -7px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
	.filter-current{
        border-bottom: 1px solid #bbb;
		&-subtitle{
			color: $toprom2-cod-grey__color;
			font-size: 16px;
            padding-bottom: 1rem;
            &:before{
                display: none;
            }
            &:after{
                content: ":";
                display: inline-block;
            }
		}
		.items{
			display: inline-block;
			padding: 0;
            border: 1px solid #ededed;
            margin-bottom: 1rem;
			.item{
				display: flex;
				margin-right: 0.5rem;
				border-radius: 15px;
				align-items: center;
				position: relative;
				z-index: 1;
                margin: 0.5rem 0;
                padding: 0.5rem 1rem;
				.filter-label{
					margin-right: 0.5rem;
				}
				.filter-value{
					color: #474747;
					font-size: 14px;
				    word-break: break-all;
                    margin-right: 0.75rem;
				}
				.action.remove{
					z-index: 1;
					padding: 0;
					display: flex;
                    align-items: center;
					position: unset;
					&:before{
						order: 1;
						margin: 0;
						text-align: center;
						background-color: $toprom2-cod-grey__color;
						border-radius: 50%;
						padding: 0.25rem;
						color: white;
						content: '\E5CD';
						font-weight: 900;
						font-size: 11px;
						text-indent: unset;
						line-height: 11px;
					}
				}
			}
		}
	}
}

/* Mobile */
@include max-screen ($screen__m){
    .filter{
        &-title{
            strong{
                color: $toprom2-cod-grey__color;
                font-size: 13px;
                background: #fff;
                top: 1rem;
            }
            strong[aria-expanded='false'] {
                margin-top: -60px;
            }
        }
        &-current{
            margin-top: 1rem !important;
        }
    }
}

.smile-es-range-slider {
    .ui-slider {
        margin-top: 10px;
    }
    .actions-toolbar {
        margin-top: 10px;
        .actions-primary {
            a:hover {
                color: $color_white;
                cursor: pointer;
            }
            float: right;
        }
    }
}

