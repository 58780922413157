//  Layout
$layout-column__sidebar-width: 2.5;
$_breadcrumbs-container-margin: 0;

// General
$font-family__base: "Myriad Pro Regular", sans-serif;
$font-color__base: $color_black;

// Colors
$color__primary: $color_blue !default;
$color__action: $color_blue !default;
$color__accent: $color_red !default;
$color__base: $color_black !default;
$color__muted: $color_grey !default;
$color__success: $color_green !default;
$color__danger: $color_red !default;

//  Navigation
$navigation__level0-item-line-height: 47px;
$navigation__level0-item-font-size: 15px;

// Links
$product-name-link__color: $color__base !default;
$product-name-link__color__active: $color__base !default;
$product-name-link__color__hover: $color__base !default;
$product-name-link__color__visited: $color__base !default;
$link__color: $color__primary;
$link__color-hover: lighten($link__color, 5%);

// Default Button
$button__color: $color__base;
$button__background: $color_white;
$button__background-hover: darken($button__background, 5%);
$button__background-active: lighten($button__background, 5%);
$button__border-color: darken($button__background, 10%);

// Action Button
$action-button__color: $color_white;
$action-button__background: $color__action;
$action-button__border-color: $color__primary;
$action-button__background-hover: $color__action;
$action-button__background-active: $color__action;

// Primary Button
$primary-button__color: $color_white;
$primary-button__background: $color__primary;
$primary-button__border-color: darken($primary-button__background, 10%);
$primary-button__background-hover: darken($primary-button__background, 5%);
$primary-button__background-active: lighten($primary-button__background, 5%);

// Cart Button
$cart-button__color: $color_white;
$cart-button__background: $color__action;
$cart-button__border-color: darken($cart-button__background, 10%);
$cart-button__background-hover: darken($cart-button__background, 5%);
$cart-button__background-active: lighten($cart-button__background, 5%);

// Ratings
$icon-star: '\E83A';
$rating-icon__color: #F0F0F0;
$rating-icon__active__color: #fdb42b;

/**
*
* Layout
*
*/

$layout-indent__width: 1rem;
$layout__max-width: 1440px;

// PolarCore Tabs mode
$polarcore-tabs__mode: 'horizontal';
