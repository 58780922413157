// /**
//  * Copyright © 2013-2017 Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Common
//  _____________________________________________

.filter {
    &.block {
        margin-bottom: 0;
    }

    &:not(.active) &-title {
        strong {
            width: calc(50% - 16px);
        }
    }

    &-title {
        strong {
            background-color: $toolbar-element-background;
            border: 1px solid $border-color__base;
            font-weight: 400;
            left: 0;
            line-height: 16px;
            padding: 7px $indent__s;
            position: absolute;
            text-align: center;
            top: 0;
            z-index: 2;

            display: flex;
            justify-content: center;
            align-items: center;

            &:before {
                @include material-icon($icon-sorter);
                padding-right: 0.5rem;
                font-size: 1em !important;
                transform: scale(1.5);
            }

            &[data-count]:after {
                color: $color-white;
                background: $color-orange-red1;
                border-radius: 2px;
                content: attr(data-count);
                display: inline-block;
                font-size: 0.8em;
                line-height: 1;
                margin: 0 $indent__xs;
                min-width: 1em;
                padding: 2px;
            }

            &.disabled {
                opacity: 0.5;
            }
        }
    }

    .block-subtitle {
        border-bottom: $border-width__base solid $border-color__base;
        font-size: $font-size__base;
        font-weight: $font-weight__semibold;
        line-height: 1em;
        padding: $indent__s $indent__s $indent__base;
    }

    &-subtitle {
        display: none;
    }

    &-current {
        margin: 0;

        .items {
            padding: $indent__xs $indent__s;
        }

        .item {
            padding-left: 17px;
            position: relative;
            z-index: 1;
        }

        .filter &-subtitle {
            border: none;
            display: block;
            padding-bottom: $indent__s;
        }

        .action.remove {
            @extend .abs-remove-button-for-blocks;
            left: -5px;
            position: absolute;
            top: -1px;
            &:before {
              text-indent: -5px;
              font-size: 30px;
            }
        }
    }

    &-actions {
        margin-bottom: $indent__m;
        padding: 0 $indent__s;
    }

    &-label {
        font-weight: $font-weight__bold;

        &:after {
            content: ': ';
        }
    }

    &-value {
        color: $filter-quantity;
    }

    &-options {
        display: none;
        margin: 0;

        &-item {
            border: $border-width__base solid $border-color__base;
            margin-bottom: $indent__s;
        }

        &-title {
            cursor: pointer;
            font-weight: 400;
            margin: 0;
            overflow: hidden;
            padding: $indent__s $indent__s + 30px 0 $indent__s;
            position: relative;
            text-transform: uppercase;
            word-break: break-all;
            z-index: 1;

            @include lib-icon-font(
            $_icon-font-content: $icon-down,
            $_icon-font-size: 36px,
            $_icon-font-position: after,
            $_icon-font-display: block
            );

            &:after {
                position: absolute;
                right: 2px;
                top: 0;
            }

            &:hover {
                color: $filter-link-hover;
            }

            .active > & {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-up,
                    $_icon-font-position: after
                );
            }
        }

        &-content {
            margin: 0;
            padding: $indent__s;

            .item {
                line-height: 1.5em;
                margin: $indent__s 0;
            }

            a {
                color: $filter-link;
                margin-left: -5px;
                margin-right: -5px;
                padding-left: 5px;
                padding-right: 7px;

                &:hover {
                    text-decoration: none;
                    color: lighten($filter-link, 10%);
                }
            }

            .count {
                color: $filter-quantity;
                padding-right: 5px;

                &:before {
                    content: '(';
                }

                &:after {
                    content: ')';
                }
            }

            .filter-count-label {
                @extend .abs-visually-hidden;
            }
        }
    }
}


//
//  Mobile
//  _____________________________________________
@include max-screen($screen__m) {
    body {
        &.filter-active {
            .page-header {
                display: none;
            }

            .page-wrapper {
                height: 0;
                margin-top: -999999em;
                visibility: hidden;
            }

            .columns {
                z-index: 999;
            }
        }
    }

    .filter {
        &.active {
            position: relative;
            visibility: visible;
            z-index: 99;

            .filter-options-item:last-child {
                margin-bottom: $indent__xl;
            }

            .filter-title {
                border-bottom: 0 solid $border-color__base;
                height: 48px;
                left: 0;
                position: fixed;
                right: 0;
                top: 0;
                z-index: 2;

                strong {
                    box-shadow: none;
                    background: none;
                    border: 0;
                    color: transparent;
                    left: auto;
                    right: 3px;
                    top: 10px;
                    @include lib-icon-font(
                    $icon-remove,
                    $_icon-font-color: $text__color__muted,
                    $_icon-font-color-active: $text__color__muted,
                    $_icon-font-color-hover: $text__color__muted,
                    $_icon-font-size: 16px,
                    $_icon-font-position: after
                    );
                    &:after:active {
                        color: $text__color__muted;
                    }
                }
            }

            .filter-subtitle {
                //background: $toolbar-background;
                display: block;
                height: 50px;
                left: 0;
                line-height: 32px;
                position: fixed;
                right: 0;
                top: 0;
                z-index: 1;
            }

            .filter-options {
                background: $color-white;
                bottom: 0;
                display: block;
                left: 0;
                overflow: scroll;
                position: fixed;
                right: 0;
                top: 50px;
                z-index: 10;
                padding: 1rem;
            }
        }
    }

    .filter {
        & &-subtitle {
            font-size: 20px;
            font-weight: $font-weight__light;
        }

        &-actions {
            margin: -35px -$indent__s $indent__m;
        }

        &-options-content {
            padding: $indent__xs $indent__s;
        }
    }

    .filter .filter-current {
        border: solid $border-color__base;
        border-width: 1px 0;
        margin: $indent__xs -$indent__s 0;

        &-subtitle {
            position: relative;
            text-transform: uppercase;
            z-index: 1;

            @include lib-icon-font(
                $_icon-font-content: $icon-down,
                $_icon-font-size: 13px,
                $_icon-font-position: before,
                $_icon-font-display: block
            );

            &:before {
                position: absolute;
                right: 10px;
                top: 10px;
            }

            &:after {
                color: $text__color__muted;
                content: ' (' attr(data-count) ')';
                font-size: .9em;
            }
        }

        &.active {
            padding-bottom: 30px;

            .block-subtitle {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-up,
                    $_icon-font-position: before
                );
            }

            .items {
                display: block;
            }

            & + .block-actions {
                display: block;
            }
        }

        & + .block-actions {
            display: none;
        }
    }

    .filter-no-options {
        .filter-title {
            &:before {
                background: rgba(255,255,255,.5);
                content: '';
                display: block;
                height: 40px;
                left: -15px;
                margin-top: -60px;
                position: relative;
                width: 100px;
                z-index: 99;
            }
        }

        .filter-content {
            margin-bottom: $indent__base;
        }
    }

    .page-with-filter {
        .columns {
            .sidebar-main {
                order: 0;
            }
        }
    }
}


/**
*
* Desktop
*
*/

@include min-screen($screen__m) {

    .filter {
        &.block {
            margin-bottom: $indent__xl;
        }

        &-title {
            display: none;
        }

        &-content {
            .item {
                margin: $indent__s 0;
            }
        }

        &-actions {
            margin-bottom: $indent__l;
        }

        &.active &-options,
        &-options {
            background: transparent;
            clear: both;
            display: block;
            overflow: initial;
            position: static;
        }

        &-subtitle {
            display: none;
            position: static;
        }
    }

    .page-layout-1column {
        .toolbar-products {
            position: absolute;
            top: 0;
            width: 100%;
        }

        .products ~ .toolbar-products {
            position: static;
        }

        &.page-with-filter .column.main {
            padding-top: 45px;
            position: relative;
            z-index: 1;
        }

        .filter {
            &.block {
                border-top: 1px solid $border-color__base;
            }

            &-content {
                margin-top: $indent__s;
            }

            &-subtitle {
                display: none;
            }

            &-options {
                &-item {
                    border: 0;
                    display: inline-block;
                    margin-right: $indent__m;
                    position: relative;

                    &.active {
                        z-index: 2;

                        .filter-options-content {
                            visibility: visible;
                        }

                        &:hover {
                            z-index: 3;
                        }

                        &:after,
                        &:before {
                            @include lib-arrow(up, 8px, $color-black);
                            bottom: -1px;
                            content: '';
                            display: block;
                            left: $indent__xs;
                            position: absolute;
                            z-index: 3;
                        }

                        &:after {
                            border-bottom-color: $color-white;
                            margin-top: 2px;
                            z-index: 4;
                        }
                    }
                }

                &-title {
                    padding: 0 $indent__base 0 0;

                    &:after {
                        right: 2px;
                        top: 3px;
                        z-index: 3;
                    }
                }

                &-content {
                    $_shadow: 0 3px 5px 0 rgba(50, 50, 50, .75);

                    background: $color-white;
                    box-shadow: $_shadow, 1;
                    border: 1px solid $border-color__base;
                    padding: $indent__xs 0;
                    position: absolute;
                    top: 100%;
                    visibility: hidden;
                    width: 180px;
                    z-index: 2;

                    .item {
                        margin: 0;
                        padding: $indent__xs;

                        a {
                            margin-left: 0;
                        }

                        &:hover {
                            background-color: $color-gray91;
                        }
                    }
                }
            }

            &-current {
                display: inline;
                line-height: 35px;

                &-subtitle {
                    color: $text__color__muted;
                    display: inline;
                    font-size: $font-size__base;
                    font-weight: normal;
                    padding: 0;

                    &:after {
                        content: ':';
                    }
                }

                .item,
                .items {
                    display: inline;
                }

                .item {
                    margin-right: $indent__m;
                    white-space: nowrap;
                }

                .action.remove {
                    line-height: normal;
                }
            }

            &-actions {
                display: inline;
                white-space: nowrap;

                & ~ .filter-options {
                    margin-top: $indent__m;
                }
            }
        }
    }
}
