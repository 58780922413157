.MagicScroll{
    .mcs-item{
        img{
            box-shadow: none !important;
            border: 1px solid #ededed !important;
        }
    }
}
// MagicZoom Mobile
@include max-screen($screen__m){
    .MagicToolboxSelectorsContainer{
        .MagicScroll{
            height: 17px !important;
        }
        .mcs-button{
            &-arrow-prev{
                left: -10px !important;
            }
            &-arrow-next{
                right: -10px !important;
            }
        }
        .mcs-item{
            padding: 0px 5px;
        }
        a{
            width: 14px;
            height: 14px !important;
            border-radius: 8px;
            background: none repeat scroll 0 0 #aaa;
            margin: 2px;
            cursor: pointer;
            display: inline-block;
            position: relative;
            text-indent: 9999px;
            vertical-align: middle;
            font-size: 0px;
            line-height: 0px;
            &.active-selector:before,
            &:hover:before {
                width: 8px;
                height: 8px;
                border: 1px solid transparent;
                border-radius: 8px;
                margin: 3px 3px;
                content: '';
                position: relative;
                display: block;
                text-indent: 9999px;
                background: #fff !important;
            }
            img{
                display: none !important;
                visibility: hidden !important;
            }
            &.mt-thumb-switcher.video-selector{
                &:before{
                    background-color: rgba(0,0,0,0.2) !important;
                    border-radius: unset;
                    margin: 0;
                }
                &:after{
                    height: 12px !important;
                    width: auto;
                    border-top: 6px solid transparent;
                    border-left: 9px solid rgba(255, 255, 255, 0.9);
                    border-bottom: 6px solid transparent;
                }
            }
        }
        img{
            display: none;
            box-shadow: none;
        }
    }
}


