$page-background: #f9f9f9;
$font-regular: inherit;
$font-medium: inherit;

body.checkout-index-index{

    .header.content {
        padding: 1rem !important;
        .action.nav-toggle{
            display: none;
        }
    }

    .modal-popup .form-shipping-address {
        max-width: initial;
    }

    .algolia-instant-selector-results {
        display: none !important;
    }

    .authentication-action{
        button{
            background: transparent;
            color: #2980b9;
            border: none;
            margin: 0 0.5rem 0 0;
            padding: 0;
            font-size: 16px;
            letter-spacing: 0;
            font-weight: 600;
            text-transform: unset;
        }
    }
    .action.action-cancel{
        background: #ddd !important;
        border-color: #bbb !important;
        color: #2a2a2a !important;
        padding: 0.7rem 1.5rem;
        border-radius: 3px;
    }
    // adress
    .fieldset {
        > .field:not(.choice) {
            > .label {
                padding-left: 0;
                padding-bottom: 0.5rem;
                font-family: $font-regular;
                font-weight: initial;
                width: 100%;
                text-align: left;
                font-size: 16px;
                &:after {
                    display: none;
                }
            }
            > .control {
                width: 100%;
                select,
                input {
                    height: 40px;
                }
                .field-error{
                    span{
                        font-size: 12px;
                        color: #e02b27;
                        display: block;
                        margin-top: 7px;
                    }
                }
            }
        }
        .field{
            margin: 0 0 20px;

            &[name="shippingAddress.street.0"] {
              label.label {
                display:none;
              }
            }
        }
        .field.choice {
            width: 100%;
            text-align: left;
            order: 20;
            margin-top: 2rem;
            &:before {
                display: none;
            }
        }
        .field-tooltip-action {
            &:before {
                font-size: 30px;
                line-height: 1.2;
            }
        }
        > .field .note {
            display: none !important;
        }
        &.address {
            display: flex;
            flex-wrap: wrap;

            & [name$=".telephone"] {
                width: 100%;
                margin: 0 0 29px;
                order: 1;
            }
            & [name$=".firstname"] {
                width: 48%;
                margin-right: 3%;
                order: 2;
                margin-top: 0;
            }
            & [name$=".lastname"] {
                width: 49%;
                order: 3;
                margin-top: 0;
            }
            fieldset.field.street {
                width: 100%;
                order: 4;
            }

            & [name$="shippingAddress.street.1"],
            & [name$="shippingAddress.street.2"],
            & [name$="shippingAddress.fax"] {
                display: none;
            }

            & [name$=".postcode"] {
                width: 32%;
                margin-right: 3%;
                margin-bottom: 0;
                order: 5;
            }
            & [name$=".city"] {
                width: 65%;
                order: 6;
                margin-bottom: 0;
            }
            & [name$=".country_id"] {
                width: 100%;
                order: 7;
                display: none;
            }
            & [name$=".company"] {
                order: 10;
                margin-top: 1rem;
                width: 49%;
            }
            & [name$=".region"] {
                display: none;
            }
        }
    }
    // customer spesified styling
	.page-wrapper {
		background: $page-background;
	}
	.page-header {
		box-shadow: 0px 1px 10px rgba(0,0,0,0.1);
	}
	.header.content {
		justify-content: space-between;
		flex-direction: row-reverse;
		.customer-support {
			font-size: 16px;
			p {
				margin: 5px 0 0 0;
				text-align: right;
			}
			strong {
				font-family: $font-medium;
			}
		}
	}
    // chat module
	.zopim {
		display: none !important;
	}

    // Checkout module
	.checkout-container {
        display: grid;
        grid-template-areas:
            'progressbar progressbar'
            'login login'
            'adress-shipping adress-shipping';
	    grid-template-columns: 50% 50%;
	    grid-template-rows: auto max-content max-content max-content;

        // progressBar
		.opc-progress-bar {
			grid-area: progressbar;
			display: flex;
			justify-content: center;
			width: 100%;
			margin: 2rem 0;
			&-item {
				&:before {
					display:none;
				}
			}
		}

        // Login form
        .modal-custom-overlay {
    		display: none; // this should be removed by default...
    	}
        .opc-summary-wrapper{
            display: none;
        }
		.authentication-wrapper {
			width: 100%;
			grid-area: login;
			display: flex;
			justify-content: space-between;
			max-width: initial;
            padding: 0rem 0 1.5rem 1rem;
            margin: 0;
			.authentication-action {
				display: flex;
				align-items: center;
				> span {
					color: #595959;
					font-size: 16px;
				}
			}
            // Customer spesified
			.secure-payment {
				display: flex;
				align-items: center;
                padding-right: 1rem;
				i {
			    	color: #fda429;
                    margin-right: 3px;
				}
				span {
					height: 24px;
					display: flex;
					align-items: center;
					font-family: $font-medium;
				}
			}
		} // login wrapper

        // login popup
		.authentication-dropdown._show {
			max-width: 50%;
			box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.2);
			border-radius: 3px;
		}

		.opc-wrapper {
			grid-area: adress-shipping;
			padding: 0;
			.step-content {
				margin: 0;
			}
            // logged in user - adress
            .shipping-address-item {
                margin: 1rem 0;
                width: 100%;
                border-color: $color-blue;
                &:after{
                    background: $color-blue;
                }
            }
		}




        // subtotal - shipping - tax - totals
        table.table-totals {
            display: none;
			grid-area: totals;
            background: #fff;
            box-shadow: 0px 1px 10px rgba(0,0,0,0.1);
            padding: 2rem;
            width: 100%;
			height: fit-content;
            margin: 0;
            tbody {
                width: 100%;
                display: block;
            }
            tr.totals {
                width: 100%;
                display: flex;
                justify-content: space-between;
                font-family: $font-medium;
                background: #fff;
				th {
					padding: 8px 10px;
				}
                &-tax {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
					th, td {
						padding: 8px 10px;
						display: flex;
						align-items: center;
						font-family: $font-medium;
					}
                }
				&.sub {
					th, td {
						padding-bottom: 4px;
					}
				}
                &.shipping {
                    span.value {
                        font-family: $font-regular;
                        font-size: 13px;
                    }
					th, td {
						display: flex;
						flex-direction: column;
					}
                }
                &.grand {
                    border-top: 1px solid #bababa;
                    margin-top: 0.5rem;
					font-size: 20px;
                    th, td {
                        border-top: none;
                        padding-bottom: 0;
                        padding-top: 1rem;
                    }
                }
            }
        }

		// minicart items
        .block.items-in-cart {
            display: none;
			grid-area: items;
			height: fit-content;
            margin: 1rem 0rem;
            background: #fff;
            box-shadow: 0px 1px 10px rgba(0,0,0,0.1);
            padding: 2rem;
            .title {
                border: none;
                cursor: auto;
                &:after {
                    display: none;
                }
                strong {
                    display: flex;
					font-size: 22px;
					font-family: $font-medium;
					font-weight: 600;
					margin: 0.5rem 0 1.5rem 0;
                    > span {
                        margin-right: 0.5rem;
                    }
                }
                .product-qty {
                    display: flex;
                    margin: 0 5px;
                    &:before {
                        content: '(';
                    }
                    &:after {
                        content: ')';
                    }
                    span {
                        margin: 0 2px;
                    }
                }
            }
            .minicart-items-wrapper {
                padding: 0;
                margin: 0;
				grid-area: items;
				height: fit-content;
                border: none;
                .minicart-items {
                    padding-right: 1rem;
                    .product-item {
						margin: 0;
                        &:last-of-type{
                            border-top: 1px solid #ccc;
                        }
                        .product-image-container {
                            max-height: 90px;
                            max-width: 90px;
                            margin-top: 0.75rem;
                        }
                        &-name {
                            font-weight: 600;
                            font-size: 1rem;
                            margin: 1rem 0 0 0;
                        }
                        &-details {
                            padding-left: 100px;
                            .product.options{
                                margin-top: 0.6rem;
                                &.active{
                                    .content{
                                        margin-top: 0.6rem;
                                    }
                                }
                            }
                            .details-qty {
                                display: flex;
							    margin-top: 0;
                                span {
                                    &.label {
                                        display: flex;
                                        margin-right: 0.25rem;
                                    }
                                    border: none !important;
                                    height: initial !important;
                                    width: initial !important;
                                }
                            }
                        }
						.subtotal {
							position: absolute;
							top: 0.85rem;
							right: 0;
							span {
								font-size: 14px;
							}
						}
                    }
                }
            }
        }

		// customer shipping information
        .opc-block-shipping-information {
            display: none;
			grid-area: shipping;
			height: fit-content;
			background: #fff;
            box-shadow: 0px 1px 10px rgba(0,0,0,0.1);
			padding: 2rem;
			.shipping-information-title {
				border-bottom: none;
				font-family: $font-medium;
				font-size: 22px;
                font-weight: 600;
				margin: 0;
				.action-edit {
					top: 0;
					&:before {
                        @extend .material-icons;
                        content: 'edit';
					}
				}
			}
        }

		&.stepTwo {
			grid-template-areas:
				'progressbar progressbar'
				'login login'
				'payment totals'
				'payment items'
				'payment shipping';


			.opc-wrapper {
				display: initial;
				width: 100%;
				padding: 0;
				grid-area: payment;
                div#giftcardaccount-placer {
                    display: none;
                }
                .form-discount {
                    max-width: initial;
                    button.action.action-apply {
                        margin-left: auto;
                        display: block;
                    }
                }
				#checkoutSteps > li {
					width: 100%;
					margin: 0 1rem 1rem;
                    .payment-option {
                        &-title {
                            margin-top: 0;
                            margin-bottom: 1rem;
                            padding-bottom: 0;
                        }
                        .form-discount{
                            .payment-option-inner,
                            .field{
                                margin-bottom: 0.75rem;
                            }
                            .actions-toolbar{
                                .action{
                                    margin-right: 0;
                                }
                            }
                        }
                        &-content{
                            .ordercomments-inner{
                                margin-top: 2.5rem;
                                .label{
                                    display: block;
                                    margin-bottom: 0.5rem;
                                }
                            }
                            .shipcomplete.field{
                                display: block;
                            }
                        }
                    }
                    .payment-method {
                        &-title {
                            margin-top: 0;
							padding: 0;
							position: relative;
                            font-weight: 600;
                            min-height: 40px;
                            margin-bottom: 0;
                            border-top: none;
							input.radio {
								margin: 0 1rem 0 0;
							}
							img {
								position: absolute;
								top: 50%;
								right: 0;
								transform: translate(0%, -50%);
								padding: 0;
							}
							div.adyen-sprite {
								position: absolute;
								top: -1px;
								right: 0;
								padding: 0;
							}
                        }
						&-content {
							padding: 0 1rem;
						}
                        #payment_form_adyen_cc {
                            position: relative;
                            padding: 1rem;
                            background: $page-background;
                            border-radius: 10px;
                            box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
                            .credit-card-types {
                                display: flex;
                                position: absolute;
                                right: 0;
                                top: -1rem;
                                margin: 0;
                                justify-content: flex-end;
                                .item {
                                    display: none;
                                    &._active {
                                        display: block;
                                    }
                                }
                            }
                            .field.choice{
                                display: flex;
                                align-items: center;
                                margin: 1rem 0rem;
                                label{
                                    margin-bottom: 0;
                                    margin-top: 2px;
                                }
                            }
                            label.label {
                                font-size: 12px;
                                padding: 0;
                                font-weight: 600;
                                margin-bottom: 6px;
                            }
                            label[for=adyen_cc_cc_type] {
                                display: none;
                            }
                            .field.type.required:first-of-type {
                                margin: 0;
                            }
                            .field.number {
                                margin-top: -10px;
                            }
                            .field.cvv {
                                margin: 0 0 10px;
                                .field-tooltip{
                                    position: relative;
                                    display: inline-block;
                                    &-action{
                                        margin-top: -4px;
                                    }
                                }
                            }
                            .fieldset > .field {
                                display: flex;
                                align-items: center;
                                flex-wrap: wrap;
                                margin: 0 0 10px;
                            }
                            select,
                            input {
                                height: 32px;
                            }
                        }
                        .fieldset > .field.type .control {
                            margin: 0;
                        }
                    }
				}
                /* VAT exempt on step two */
                .vat-exempt-outer-wrapper{
                    padding: 2rem;
					margin: 0rem 1rem 1rem;
					height: fit-content;
					background: #fff;
					box-shadow: 0 1px 10px rgba(0,0,0,.1);
					width: 100%;
                    .vat-exempt-wrapper{
                        display: block !important;
                        #vat-exempt{
                            display: block !important;
                            grid-area: vat;
                            margin-bottom: 1rem;
                            .step-title{
                                margin-bottom: 1rem;
                            }
                            &-declains{
                                #co-vatexempt{
                                    padding: 0;
                                    border: none;
                                    .label{
                                        margin-bottom: 0.25rem;
                                        display: block;
                                        font-weight: 600;
                                    }
                                    ul{
                                        margin-top: 1.5rem;
                                    }
                                }
                            }
                        }
                    }
                }
			}
			table.table-totals {
				display: block;
			}
			.block.items-in-cart {
				display: block;
			}
			.opc-block-shipping-information {
				display: block;
			}
		}
		.opc-wrapper {
			width: 100%;
			display: flex;
			#checkoutSteps {
				width: 100%;
				display: flex;
                /* VAT Exempt hide on step 1 */
                .vat-exempt-wrapper{
                    display: none;
                    #vat-exempt{
                        display: none;
                    }
                }
				> li {
					width: 48%;
					padding: 2rem;
                    margin: 0 1%;
					height: fit-content;
					background: #fff;
                    box-shadow: 0px 1px 10px rgba(0,0,0,0.1);
					.actions-toolbar {
						margin: 0;
						padding: 0;
                        .primary {
                            width: 100%;
                        }
						.action.primary {
							width: 100%;
                            background-color: $toprom2-green__color;
                            border-color: darken($toprom2-green__color, 12%);
                            &:hover{
                                background-color: darken($toprom2-green__color, 3%);
                            }
						}
					}
				}
			}
			.step-title {
				font-family: $font-medium;
                font-weight: 600;
				border: none;
				padding-top: 10px;
			}
			.form-login,
			.form-shipping-address {
				max-width: initial;
				margin: 20px 0 0 0;
				border: none;
				.hidden-fields {
					display: none !important;
				}
                .field.required {
                    margin-bottom: 0;
                }
			}
			// newsletter
			#newsletter {
				display: none;
			}
			&.fieldset {
				display: none;
			}
		}

		// customer lookup module
		#customer-lookup {
			&-form {
				fieldset {
					max-width: initial;
				}
				button {
					height: 40px;
					padding: 0 3rem;
				}
				label.label {
					padding-left: 0;
					padding-bottom: 0.5rem;
					font-family: $font-regular;
					font-weight: initial;
					width: 100%;
					text-align: left;
					font-size: 16px;
				}
				input {
					height: 40px;
				}
			}
		}


		// shipping methods
		.table-checkout-shipping-method {
			max-width: initial;
			width: 100%;
            min-width: initial;
			tbody {
				&:first-of-type {
					display: block;
					margin-top: 1rem;
				}
			}
			thead {
				display: none;
			}
            //mypack
            #vconnect_allinone_tr {
                display: flex;
                width: 100%;
                td {
                    width: 100%;
                }
            }
            // magento
			tr.row {
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				border: 1px solid #bababa;
				border-radius: 10px;
				margin: 1rem 0;
				position: relative;
				td {
					border: none;
					height: fit-content;
					padding: 15px 15px 0 15px;
					&.col {
						&:nth-child(1) {
							// checkbox
							order: 1;
						}
						&:nth-child(2) {
							// price
							order: 5;
							width: 100%;
							padding: 0 15px 15px 45px;
						}
						&:nth-child(3) {
							// location
							order: 2;
						    font-family: $font-medium;
						}
						&:nth-child(4) {
							// carrier
							display: none;
						}
						&:nth-child(5) {
							// logo
							order: 4;
							position: absolute;
							right: 0.5rem;
							top: 50%;
							transform: translate(0%, -50%);
							padding: 0;
							img {
								margin: 0;
							}
						}
					}
				}
				&:first-of-type {
					margin-top: 0;
				}
			}
		}

		// payment methods
		.payment-method {
			margin: 1rem 0;
			padding: 1rem;
			border: 1px solid #bababa;
			border-radius: 5px;
			display: flex;
			flex-direction: column-reverse;
            &._active {
                border-color: #000;
            }
            &-title.field.choice {
                .klarna-payments-method-cell {
                    float: left;
                }
            }
		}

        // giftcard

        #giftcard-form {
            display: flex;
            flex-wrap: wrap;
            .payment-option-inner {
                margin: 0;
                width: 100%;
            }
            .actions-toolbar {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                .primary {
                    width: fit-content !important;
                    button {
                        width: fit-content;
                        padding: 0.75rem 2rem;
                    }
                }
                .secondary {
                    button {
                        height: 39px;
                        border-bottom: 2px solid #585858;
                    }
                }
            }
        }

		// radio buttons
		.radio {
		  -webkit-appearance: none;
		  height: 18px;
		  width: 18px;
		  position: relative;
		  transition: all 0.15s ease-out 0s;
		  background: #fff;
		  color: #fff;
		  cursor: pointer;
		  margin: 0;
          display: flex;
          justify-content: center;
          align-items: center;
		  &:focus {
			box-shadow: none;
		  }
		  &:hover {
			background: #eaeaea;
		  }
		  &:checked {
			border: 2px solid #2980b9 !important;
			&:before {
                content: " ";
                height: 10px;
                width: 10px;
                background: #2980b9;
                border-radius: 50%;
			}
		  }
		  &.radio {
			border-radius: 50%;
			border: 2px solid #5e5e5e;
			&:after {
			  border-radius: 4px;
			}
		  }
		}

        &.customergroup-b2b {
            #checkout-step-shipping {
                .new-address-popup {
                    display: none;
                }
            }
        }
	} // checkout container

	.stormberg-footer-content,
	.page-footer,
	.page-bottom,
	.copyright {
		display: none;
	}

// mobile
	@include max-screen($screen__m) {
		.page-main {
			margin-bottom: 0;
		}
		.columns .column.main {
			padding-bottom: 0;
		}
        .modal-popup.modal-slide {
            left: 0;
        }
		.nav-toggle {
			display: none;
		}
		.header.content {
			.customer-support {
				width: 50%;
				p:first-child {
					display: none;
				}
			}
			#menu-button {
				display: none;
			}
		}
		.opc-progress-bar {
			margin: 0.5rem 0 0 0;
			&-item {
				&:after {
					left: calc(100% - 1.15rem);
				}
			}
		}
		.checkout-container {

			.authentication-wrapper {
				padding: 0 0.5rem;
				.secure-payment {
					display: none;
				}
				.authentication-action {
                    display: block;
					button {
						white-space: nowrap;
                        margin-top: -5px;
                        margin-right: 4px;
					}
				}
			}
			.authentication-dropdown._show {
				max-width: 100%;
			}
			.opc-estimated-wrapper {
				display: none;
			}
			.opc-wrapper {
				.step-content {
					margin: 0;
				}
				#checkoutSteps {
					flex-direction: column;
                    list-style: none;
                    padding: 0;
					> li {
						width: calc(100% - -2rem);
						margin: 1rem -1rem;
						padding: 1rem;
					}
				}
				.form-shipping-address {
					margin-bottom: 0.25rem;
				}
			}
			#checkout-step-shipping_method {
				margin: 0;
				.table-checkout-shipping-method {
					margin-bottom: 0;
				}
				.actions-toolbar {
					button {
						margin: 0;
					}
				}
			}

			.checkout-payment-method {
				.payment-method  {
					&-title {
						padding: 15px 0;
					}
				}
			}

			&.stepTwo {
				grid-template-areas:
					'progressbar'
					'login'
                    'vat'
					'totals'
					'payment'
					'items'
					'shipping';
			    grid-template-columns: 100%;

				.block.items-in-cart,
				.opc-block-shipping-information,
				table.table-totals {
					padding: 1.25rem;
				    width: calc(100% - -2rem);
					max-width: initial;
				}

				table.table-totals {
					margin: 2rem -1rem;
				}

                #payment_form_adyen_cc {
                    .credit-card-types {
                        right: 0.25rem;
                        top: 0.5rem;
                    }
                }

				.block.items-in-cart {
    				margin: 0rem -1rem 2.5rem -1rem;
					.minicart-items-wrapper {
						.minicart-items {
							.product-item {
								.subtotal {
								   position: initial;
								   margin: 0.5rem 0;
							   }
							}
						}
					}
				}

				.opc-block-shipping-information {
					margin: 0rem -1rem;
				}

				.opc-wrapper {
					width: 100%;
				}
				#checkoutSteps {
					> li {
						justify-content: center;
						&.checkout-payment-method,
                        &#vat-exempt{
							padding: 1rem 2rem;
							width: calc(100% - -2rem);
							margin: 0 -1rem 1rem -1rem;
						}
					}
				}
			}
		}
	}
}

