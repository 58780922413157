.meganav {
    .meganav__list-root {
        > .meganav__item.level0 {
            & a.meganav__anchor.level0.meganav__list--open,
            &.info-link a.meganav__anchor.level0,
            &.meganav__item--open a.meganav__anchor.level0 {
                color: #ffffff;
                background-color: $toprom2-main__color;
                img {
                    filter: grayscale(1) contrast(0) brightness(2); //Make icon white
                }
            }
        }
    }
}
