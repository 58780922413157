.toolbar {
    select {
        background-color: #fff;
    }
    &-amount {
        margin: 0 auto 0 0;
        font-size: 20px;
    }
    &-products {
        .toolbar-amount {
            margin: 0 auto 0 0;
        }
    }
}

.sorter {
    &-options {
        border: 1px solid #595959;
        border-bottom-width: 2px;
        border-radius: 3px;
    }
}

//
//  Desktop
//  _____________________________________________
@include min-screen($screen__m) {
    .modes {
        display: none;
    }
    .limiter {
        .products.wrapper ~ .toolbar & {
            padding-right: 20rem;
        }
    }
}
