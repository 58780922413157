$image-height: auto;
$image-width: 100vw;

$image-height-tablet: 700px;
$image-height-mobile: 545px;

.cms-no-route{
    #maincontent{
        height: 842px;
        max-width: 100vw;
        .column.main{
            max-width: 100vw;
            width: calc(100vw - 15px);
        }
        .page-title-wrapper{
            display: none;
        }
        .page-404{
            img{
                position: absolute;
                left: 0;
                z-index: -1;
                height: $image-height;
                width: $image-width;
                object-fit: cover;
                object-position: 35% 15%;
            }
            .title-404{
                position: absolute;
                top: 10rem;
                left: 5rem;
                padding: 2.5rem 1.5rem;
                background: rgba(255, 255, 255, 0.75);
                &-whoops{
                    font-size: 24px;
                    color: $toprom2-cod-grey__color;
                    font-weight: 700;
                    margin-top: 0;
                }
                &-description{
                    font-size: 16px;
                    color: $toprom2-cod-grey__color;
                    margin-top: 1.5rem;
                    max-width: 600px;
                    line-height: 24px;
                }
                .button-wrapper{
                    margin-top: 2rem;
                    .action.primary{
                        padding: 1rem 2rem;
                        background: $toprom2-cta-button__background-color;
                        border-radius: 3px;
                        border: none;
                        border-bottom: 2px solid darken($toprom2-cta-button__background-color, 6%);
                        transition: all 100ms ease;
                        &:hover{
                            background: darken($toprom2-cta-button__background-color, 4%);
                        }
                        .home{
                            color: #fff;
                            text-decoration: none;
                            font-size: 16px;
                            letter-spacing: 1px;
                            font-weight: 500;
                            text-transform: uppercase;
                            padding: 1.5rem;
                        }
                    }
                }
            }
        }
    }
}

/* remove padding on smaller devices */
@include max-screen(1440px){
    .cms-no-route{
        #maincontent{
            padding: 0 !important;
        }
    }
}

/* Tablet */
@include screen($screen__m, $screen__l){
    .cms-no-route{
        #maincontent{
            height: $image-height-tablet;
            margin-bottom: 0;
            .page-404{
                img{
                    height: $image-height-tablet;
                    object-position: 50% 15%;
                }
                .title-404{
                    left: 0;
                    right: 0;
                    top: 25%;
                    width: 65%;
                    top: 5rem;
                    margin: 0 auto;
                }
            }
        }
    }
}

/* Mobile */
@include max-screen($screen__m){
    .cms-no-route{
        #maincontent{
            height: 500px;
            .page-404{
                img{
                    height: $image-height-mobile;
                }
                .title-404{
                    top: 1rem;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    width: 90%;
                    &-whoops{
                        font-size: 24px;
                        line-height: 32px;
                    }
                    &-description{
                        padding: 0rem;
                    }
                    .button-wrapper{
                        margin-top: 1.5rem;
                        .action.primary{
                            padding: 1rem 1.75rem;
                            .home{
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}

