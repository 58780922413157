//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    header .header {
        &.content {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            padding: 0.5rem;
            min-height: 50px;
            & > .nav-toggle {
                position: static;
                padding: 0;
                margin: 0;
                display: block;
            }
            & > .minicart-wrapper {
                position: static;
                padding: 0;
                margin: 0;
            }
            & > .logo {
                margin: 0rem auto 0rem 1rem;
                padding: 0rem;
                img {
                    width: 110px;
                }
            }
            & > .block.block-search {
                width: 100%;
                margin-top: 0rem;
                order: 25;
                z-index: 9;
                .control {
                    border-width: 0;
                }
                .field.search {
                    position: relative;
                    .label {
                        top: 0.1rem;
                    }
                }
            }
            .polarcore-header-links{
                .minicart-wrapper{
                    margin-top: 0rem;
                }
            }
        }
    }

    header .nav-toggle:before {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        line-height: 24px;
        background: $polarcore-nav-toggle-special__background;
        box-shadow: 0 0px 0 4px $polarcore-nav-toggle-special__background;
    }

}

