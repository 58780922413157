.catalog-product-view {
	.clearfix:after {
		content: '';
		visibility: hidden;
		display: block;
		height: 0;
		clear: both;
	}
    .swatch-opt{
        margin: 10px 0px;
    }

	.swatch-attribute-label {
		font-weight: bold;
		position: relative;

		&.required {
			padding-right: 10px;
		}

		&[data-required="1"]::after {
			content: '*';
			color: red;
			position: absolute;
			right: -11px;
			top: -2px;
			font-weight: bold;
			font-size: 1em;
		}
	}

	.swatch-attribute-selected-option {
		color: #646464;
		padding-left: 17px;
	}

	.swatch-attribute-options {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		margin-top: 10px;
	}
	.swatch-attribute-options:last-child {
		margin-bottom: 0;
	}

	#swatches-content {
		display: none;
	}

	.swatch-option {

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 5px;
		cursor: pointer;
		position: relative;

		.inner {
			width: 100%;
		}

		&.text {
			box-sizing: content-box;
			background: $color_lightgrey;
			border: 1px solid #f0f0f0;
			color: #7f7f7f;
			font-size: 12px;
			font-weight: 700;
			line-height: 20px;
			padding: 4px 4px;
			min-width: 22px;
			min-height: 30px;
			margin-right: 7px;
			margin-top: 7px;
			border-radius: 0;
		}

		&.selected {
			.inner {
				border: $polarcore-swatch-option-selected__border;
				color: #333;
				.value {
					position: relative;
					&:after {
						@include material-icon($icon-checkmark);
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						font-size: 2rem;
						color: white;
					}
				}
			}
		}

		&.text.selected {
			background-color: #fff;
		}

		&:not(.disabled):hover {
			.inner{
				border: 1px solid #f0f0f0;
				color: #333;
			}
		}

		&.color,
		&.image {
			min-height: 95px;
			min-width: 75px;
			border-radius: 0;
			background-size: auto !important;
			background-position: top !important;
			.value {
				display: flex;
				width: 100%;
			}
			img {
				height: 100%;
				width: 100%;
			}
			p {
				background: white;
				margin: 0;
				padding: 5px;
				font-size: 12px;
				width: 100%;
				text-align: center;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			&.selected {
				.inner {
					color: #333;
					box-shadow: 0px 1px 1px rgba(0,0,0,0.7);
					border:none;
					&:hover {
						box-shadow: 0px 1px 1px rgba(0,0,0,0.2);
					}
				}
				.inner {
					opacity: 1;
				}
			}
			&:not(.disabled):hover {
				opacity: 0.8;
				border: 2px solid transparent;
				&.selected {
					opacity: 1;
				    border: 2px solid #11525d;
					.inner {
						box-shadow: 0px 1px 1px rgba(0,0,0,0.7);
						outline: 0;
						border: 0;
					}
				}
				.inner {
					box-shadow: 0px 1px 1px rgba(0,0,0,0.2);
					border: 0;
				}
			}
		}

		&.disabled {
			cursor: default;
			opacity: 0.2;
			&:after {
				display: none;
			}
		}
	}

	.swatch-attribute {
		.swatch-option {
			background: #ffffff;
			color: $font-color__base;
			border: none;
			box-shadow: 0px 1px 1px rgba(0,0,0,0.33);
			font-weight: 500;
			width: fit-content;
			padding: 0.5rem 1rem;
			border: 2px solid transparent;
			&.selected {
			    border: 2px solid #11525d;
				&:before {
					position: absolute;
                    @include material-icon('done');
					bottom: -1px;
					right: -1px;
					color: #fff;
					z-index: 9;
					font-size: 1rem;
					font-weight: 600;
				}
				&:after {
					content: '';
					position: absolute;
					bottom: -3px;
					width: 0;
					height: 0;
					right: -10px;
					border-left: 15px solid transparent;
					border-right: 15px solid transparent;
					border-top: 15px solid #11525d;
					clear: both;
					transform: rotate(-45deg);
				}
			}
		}
	}

	.swatch-option-tooltip {
		display: none !important;
	}

	.swatch-option-tooltip,
	.swatch-option-tooltip-layered {
		.corner {
			left: 40%;
			position: absolute;
			bottom: 0;
			height: 13px;
			&::before {
				content: '';
				position: relative;
				top: 2px;
				left: 2px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 8px 8.5px 0 8.5px;
				border-color: #1672a0 transparent transparent transparent;
				font-size: 1px;
			}
			&::after {
				content: '';
				position: relative;
				top: 1px;
				left: -14px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 7px 7.5px 0 7.5px;
				border-color: #1672a0 transparent transparent transparent;
				font-size: 1px;
			}
		}
		.image {
			display: block;
			position: relative;
			height: 150px;
			width: 150px;
			min-width: 150px;
			background-size: cover !important;
			margin: 0 auto;

		}
	}

	.swatch-option-tooltip .title {
		display: block;
		position: relative;
		overflow: hidden;
		min-height: 20px;
		color: rgb(40, 40, 40);
	}

	.swatch-option-tooltip .content {
		display: flex;
		flex-direction: column;
		height: 100%;
		padding: 1rem;
		text-align: left;
		color: #1d1d1d;
	}

	.swatch-attribute {
		margin: $indent__m 0;
		&:first-of-type {
			margin-top: 0;
			margin-bottom: 10px;
			display: block;
			.swatch-option {
				&.disabled {
					display: block;
				}
			}
		}
		&:last-of-type {
			margin-bottom: 10px;
		}
	}

	.swatch-more {
		display: inline-block;
		margin: 2px 0;
		text-decoration: none !important;
		position: static;
		z-index: 1;
	}

	// Layered Features
	.swatch-option-link-layered {
		position: relative;
	}

	.swatch-option-tooltip-layered {
		width: 140px;
		position: absolute;
		background: #fff;
		color: rgb(148, 148, 148);
		border: 1px solid #adadad;
		display: none;
		z-index: 999;
		left: -47px;
	}

	.swatch-visual-tooltip-layered {
		height: 160px;
		top: -170px;
	}

	.swatch-textual-tooltip-layered {
		height: 30px;
		top: -40px;
	}

	.swatch-option-link-layered:hover > .swatch-option-tooltip-layered {
		display: block;
	}

	.swatch-attribute-options .swatch-option-tooltip-layered .title {
		width: 100%;
		height: 20px;
		position: absolute;
		bottom: -5px;
		left: 0;
		color: rgb(40, 40, 40);
		text-align: center;
		margin-bottom: 10px;
	}

	.swatch-option-disabled:after {
		display: none;
	}

	.swatch-option-disabled {
		outline: none !important;
		cursor: default;
		border: 0;
	}

	// Bugfix for Add To Cart button
	.swatch-opt-listing {
		margin-bottom: 10px;
	}
	.swatch-option-loading {
		content: url(../Magento_Swatches/images/loader-2.gif);
	}

	// Magento 2.2 fix input
	// https://github.com/magento/magento2/blob/2.2.0/app/code/Magento/Swatches/view/frontend/web/css/swatches.css

	.swatch-input {
		left: -1000px;
		position: absolute;
		visibility: hidden;
	}
}
