$color_red: #ed1b2f;
$color_blue: #1c5275;
$color_green: #40c353;
$color_orange: #ff7600;
$color_grey: #595959;
$color_darkgrey: #474747;
$color_darkgrey2: #3C3C3C;
$color_lightgrey: #f7f7f7;
$color_black: #1a1a1a;
$color_white: #ffffff;

$toprom2-green__color: #00C853;
$toprom2-gray__color: #808080;
$toprom2-main__color: $color_blue;
$toprom2-cod-grey__color: $color_black;

$toprom2-cta-button__background-color: $toprom2-green__color;
