button,
a.button,
button.button {
    -webkit-appearance: none;
    font-weight: 400;
    border-radius: 3px;
    padding: 0.7rem 1.5rem;
    font-size: 16px;
    text-transform: capitalize;
    background-color: $button__background;
    border-width: 0 0 2px 0;
    border-style: solid;
    border-color: $button__border-color;
    color: $button__color;
	line-height: 1em;
    white-space: nowrap;
    position: relative;
    display: inline-block;
    width: auto;
    &:hover {
        background-color: $button__background-hover;
        text-decoration: none;
        border-width: 0 0 2px 0;
    }
    &:active {
        background-color: $button__background-active;
    }
    &.action {
        background-color: $action-button__background;
        border-color: $action-button__border-color;
        color: $action-button__color;
        border-width: 1px 1px 2px 1px;
        &:hover {
            background-color: $action-button__background-hover;
        }
        &:active {
            background-color: $action-button__background-active;
        }
    }
    &.primary {
        background-color: $primary-button__background;
        border-color: $primary-button__border-color;
        color: $primary-button__color;
        &:hover {
            background-color: $primary-button__background-hover;
        }
        &:active {
            background-color: $primary-button__background-active;
        }
    }
    &.tocart,
    &.cart {
        background-color: $cart-button__background;
        border-color: $cart-button__border-color;
        color: $cart-button__color;
        &:hover {
            background-color: $cart-button__background-hover;
        }
        &:active {
            background-color: $cart-button__background-active;
        }
        .products-grid & {
            background-color: $color_white;
            border-color: $primary-button__background;
            color: $primary-button__background;
            border-width: 1px 1px 2px 1px;
            &:hover,
            &:active {
                background-color: $color_white;
                color: darken($primary-button__background, 5%);
                border-color: darken($primary-button__background, 5%);
            }
        }
    }
}
