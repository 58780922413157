.tw-productlabels{
    &__label--discount{
         .tw-productlabels__text{
             background: #d60606 !important;
             color: #fff;
         }
    }
    .b2b-label{
        display: none;
    }
     &__text{
         padding: 0.6rem 2rem !important;
         opacity: 0.85;
         text-transform: uppercase;
         letter-spacing: 0.5px;
     }
     &__label--custom-designed-in-norway{
        width: 150px;
        height: 32px;
        display: flex;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.85);
        img{
            width: 134px;
            margin-left: 8px;
        }
     }
     &__label--custom-designed-in-norway-germany{
        width: 165px;
        height: 34px;
        display: flex;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.85);
        img{
            width: 100%;
            margin-left: 8px;
        }
     }
}

/* product page */
.product.media{
    .tw-productlabels{
        left: 7.5rem;
        top: -0.25rem;
    }
}

/* Tablet only */
@include screen($screen__m, $screen__l){
    .product.media{
        .tw-productlabels{
            left: 6.5rem;
        }
    }
}

/* Mobile only */
@include max-screen($screen__m){
    .product.media{
        .tw-productlabels{
            left: -0.25rem;
        }
    }
}
