
/* Desktop */
@include min-screen($screen__m) {
    .header-links-meganav {

        & > ul {
            list-style: none;
        }

        .meganav-trigger {
            text-decoration-line: none;
            font-size: 18px;
            color: $font-color__base;
            vertical-align: middle;
            &:after {
                @include material-icon('\E5C5');
                position: relative;
                top: 5px;
                left: 5px;
                transition: transform 500ms ease;
            }
            &.open {
                &:after {
                    transform: rotate(180deg);
                }
            }
        }

        .meganav-wrapper {
            display: none;
            position: absolute;
            width: auto;
            left: 0;
            top: 100%;
            margin-top: 0.25rem;
            z-index: 15;
            background: #ffffff;
            border-top: 1px solid #e2e2e2;

            &.meganav-open {
                display: block;
                .meganav {
                    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
                }
            }

            .category-image {
                margin: 0;
                height: calc(100% - 2rem);
                overflow: hidden;
                text-align: center;
                border-top: 1px solid #161d36;
                margin-top: 0.9rem;
                padding-top: 0.9rem;
                display: flex;
                align-items: center;
                img {
                    height: auto;
                    max-height: 100%;
                    width: auto;
                    max-width: 100%;
                    margin: 0 auto;
                }
            }

            .meganav__list-root {
                flex-direction: column;
                width: 300px;
                margin-left: 0;
                a.meganav__anchor.level0 {
                    color: $color_white !important;
                    background-color: $color_blue;
                }

                .meganav__item.level0 {
                    padding: 0;
                    width: 100%;
                    // temp removal of access and support menu point
                    &[data-id="61"]{
                        display: none;
                    }
                    > a {
                        display: flex;
                        justify-content: flex-start;
                        text-decoration-line: none;
                        width: 100%;
                        font-size: 16px;
                        color: $font-color__base;
                        padding: 0.5rem 1rem;
                        min-height: 40px;
                        display: flex;
                        align-items: center;
                        border-top: 1px solid #fff;
                        &:after {
                            font-size: 20px;
                            margin: -6px 0;
                        }
                    }
                    &:not(.has-children) {
                        border: none;
                    }
                }

                .meganav__item.level1 {
                    > a {
                        text-decoration-line: none;
                        font-size: 16px;
                        color: $font-color__base;
                        font-weight: bold;
                        display: block;
                        height: 100%;
                        padding-top: 0;
                    }
                }

                .meganav__item.level2 {
                    > a {
                        text-decoration-line: none;
                        font-size: 14px;
                        color: $font-color__base;
                    }
                }

                .meganav__item.level0.has-children {
                    > a {
                        position: relative;
                        font-size: 16px;
                        &:first-of-type{
                            border-top: none;
                        }
                        &:after {
                            @include material-icon($icon-arrow-down-thin);
                            position: absolute;
                            top: 15px;
                            right: 10px;
                        }
                    }
                    &.meganav__item--open {
                        border-bottom: 2px solid transparent;
                        > a {
                            &:after {
                                transform: rotateZ(-90deg);
                            }
                        }
                    }
                    .meganav__list.level1 {
                        display: none;
                        &.meganav__list--open {
                            /* changing menu to flex-based since Grid doesn't work in Internet Explorer */
                            display: flex;
                            justify-content: flex-start;
                            flex-direction: row;
                            width: 1140px;
                            max-width: 76vw;
                            position: absolute;
                            top: 0;
                            left: 300px;
                            right: 0;
                            margin: 0;
                            border-top: 0;
                            border-left: 1px solid #e2e2e2;
                            box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
                            background-color: #ffffff;
                            padding: 1rem;
                            height: auto;
                            min-height: 100%;
                            li{
                                margin: 0rem 1.5rem;
                                padding-top: 0;
                                padding-bottom: 0;
                                max-width: 20%;
                                /* max-width not working in IE */
                                width: 20%;
                            }
                        }
                    }
                    .meganav__list.level1.meganav__list--open {
                        .meganav__item.level1.has-children {
                            flex-grow: 1;
                            & > a {
                                display: block;
                                height: auto;
                                border-bottom: 1px solid $font-color__base;
                                margin-bottom: 1rem;
                            }
                            ul {
                                -ms-grid-columns: 1fr;
                                grid-template-columns: 1fr;
                                li {
                                    margin: 0;
                                    width: 100%;
                                    display: block;
                                    max-width: none;
                                    a {
                                        line-height: 2;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// Tablet
@include screen($screen__m, $screen__l){
    .header-links-meganav .meganav-wrapper .meganav__list-root{
        width: 28vw;
        .meganav__item.level0.has-children .meganav__list.level1.meganav__list--open{
            width: 72vw;
            left: 28vw;
        }
    }
}

// Mobile
@include max-screen($screen__m) {
    .header-links-meganav {
        > ul {
            display: none;
        }

        .category-image {
            display: none;
        }

        .meganav-wrapper {
            .meganav--mobile{
                .meganav__list-root{
                    right: 0;
                    overflow-y: auto;
                    height: 100vh;
                }
                .meganav__item--back {
                    display: flex;
                    justify-content: flex-end;
                    font-size: 0;
                    color: transparent;
                    padding-bottom: 2rem;
                    &:before {
                        @include material-icon('clear');
                        font-size: 25px;
                        color: #000;
                    }
                }
                .meganav__item--title {
                    position: absolute;
                    left: 0;
                    top: 0;
                    background-color: transparent;
                    font-size: 20px;
                }

                .meganav__list-root {
                    .meganav__anchor.level0 {
                        display: flex;
                        align-items: center;
                        border-bottom: 2px solid $color_white;
                        text-decoration: none;
                        color: $font-color__base;
                        background: $color_white;
                        &:after {
                            transform: rotateZ(90deg);
                            font-size: 20px;
                        }
                        > a:last-of-type{
                            border-bottom: none;
                        }
                        &.meganav__list--open {
                            &:after {
                                transform: rotateZ(270deg);
                            }
                        }
                    }
                }

                .meganav__list.level1 {
                    > li  a {
                        display: flex;
                        align-items: center;
                        background-color: #f0f0f0;
                        font-size: 1em;
                        color: #000;
                        text-decoration-line: none;
                        padding: 1rem 2rem;
                        line-height: 1;
                    }
                    .meganav__item:not(.level1):not(.level2):not(.meganav__item--back):not(.meganav__item--title) {
                        display: none;
                    }
                    .meganav__anchor.level1 {
                        &:after {
                            transform: rotateZ(90deg);
                            font-size: 20px;
                        }
                        &.meganav__list--open {
                            &:after {
                                transform: rotateZ(270deg);
                            }
                        }
                    }
                }
                .meganav__list.level2 {
                    > li a {
                        padding-left: 3rem;
                    }
                }
            }

            .meganav__list--open + .meganav__list {
                transform: none;
                position: static;
                transition: none;
            }

            .meganav__list {
                transition: none;
                .meganav__item--back,
                .meganav__item--title {
                    display: none !important;
                }
            }
        }
    }
}

.meganav {
    .category-icon {
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
    }
}

/* Obfuscator */
.obfuscator {
    display: block;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 30;
    visibility: hidden;
    transition: background 0.2s;
    &.is-visible{
        visibility: visible;
    }
    @include max-screen($screen__m) {
        top: 0;
        height: 100%;
        display: none;
    }
}

