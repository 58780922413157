.minicart-wrapper.active
    .block-minicart {
        background: #f2f2f2;
        z-index: 10000;
        &:before{
            right: 31px;
        }
        &:after{
            right: 30px;
        }
        &.empty{
            background: rgb(250, 250, 250);
        }
        .block-title {
            display: block;
            text-transform: uppercase;
            margin: -0.5rem 0 1rem 0;
            border: none;
            span.qty {
                &:before{
                    content: "( ";
                    display: inline-block;
                    margin-right: 0.15rem;
                }
                &:after{
                    content: " )";
                    display: inline-block;
                    margin-left: 0.15rem;
                }
            }
            > strong{
                letter-spacing: 0.5px;
            }
        }
        .block-content {
            display: flex;
            flex-direction: column;
            margin-top: -2rem;
            margin-bottom: 0;
        }
        .details-qty{
            display: flex;
            .label{
                display: none;
            }
            button{
                font-size: 12px;
                line-height: 12px;
            }
            .p2-qty-wrapper{
                .qty-decrease,
                .qty-increase{
                    width: 30px;
                    height: 35px;
                }
                .item-qty{
                    width: 35px;
                    height: 35px;
                    border-left: none;
                    border-right: none;
                    margin-right: 0;
                    border-color: #E0E6EE;
                }
            }
        }
        .block-content{
            .subtotal{
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 1rem 0 0;
                .label{
                    display: block;
                    span{
                        font-size: 1.2rem;
                        margin-right: 0;
                        &:after{
                            content: ":";
                        }
                    }
                }
            }
            .actions{
                .action.viewcart{
                    button{
                        text-transform: uppercase;
                    }
                }
            }
        }
        .product.actions{
            .primary{
                display: none;
            }
        }
        .product-item-details{
            .product-item-name{
                a{
                    color: #1a1a1a;
                    font-size: 1rem;
                }
            }
            .price-container{
                margin-bottom: 1rem;
            }
            .product.options.list{
                margin-top: 0.5rem;
            }
        }

        button#btn-minicart-close {
            visibility: hidden;
        }

        button#top-cart-btn-checkout {
            position: absolute;
            top: 8px;
            right: 10px;
            width: 42%;
            padding: .5rem;
            font-size: 0.9rem;
			font-weight: 700;
        }

        .minicart-items-wrapper {
            background: #fff;
            height: inherit !important;
            max-height: 350px;
            overflow-x: hidden;
        }

        .items-total {
            display: none;
        }

        .subtotal {
            order: 10;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin: -0.5rem 0 -1rem 0;
            span.label{
                margin-right: .5rem;
                font-weight: 600;
                span{
                    margin-right: -.5rem;
                }
            }
        }

        .minicart-wrapper .minicart-widgets {
            display: none;
            margin: 0;
        }

        .secondary {
            span {
                font-size: 16px;
                letter-spacing: 0.5px;
            }
        }

        div#minicart-widgets {
            display: none;
            margin-top: 0;
        }

}

.minicart-wrapper {
    .block-minicart {
        display: none;
    }
}

@include max-screen($screen__m){
    .minicart-wrapper.active{
        .block-minicart{
            .block-title{
                font-size: 0.8rem;
            }
            &:before{
                right: 18px;
            }
            &:after{
                right: 17px;
            }
            .actions{
                .primary{
                    #top-cart-btn-checkout{
                        width: 45%;
                        padding: 0.4rem 0 0.2rem;
                        line-height: 20px;
                    }
                }
            }
        }
    }
}


