.wpcloud-root.wpcloud-page {
	.wpb_wrapper {
		> h2 {
			margin-bottom: 2rem !important;
			padding-bottom: 1.25rem !important;
			position: relative;
		  /*&:before {
			    content: '';
				margin: auto;
				height: 2px;
				background: #161d36;
				position: absolute;
				top: 100%;
				left: 50%;
				-webkit-transform: translate(-50%,0%);
				transform: translate(-50%,0%);
				width: 20%;
			 }*/
		}
	}
}

.cms-home {
    .card-wrapper.wpcmdl-grid--width-full {
        width: 100vw;
        margin: 0 calc((100% - 100vw)/ 2);
        height: unset;
    }
}
